import styled from "styled-components";

export const StyledNotFoundContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.primaryBackgroundColor};
`;

export const StyledNotFoundGroup = styled.div`
  max-width: 800px;
  height: 500px;
  position: relative;
  background-color: ${p => p.theme.secondaryBackgroundColor};
`;

export const StyledLeftContainer = styled.div`
  position: absolute;
  width: 50%;
  padding-left: 20px;
  background-color: ${p => p.theme.primaryColor};
  left: 0;
  top: 0;
  bottom: 0;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
`;

export const StyledRightContainer = styled.div`
  float: right;
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  height: 100%;
  padding-right: 20px;
`;

export const StyledTitle = styled.h2`
  display: inline-block;
  width: 100%;
  font-size: 50px;
  line-height: 1;
  margin: 0px;
  color: ${p => p.theme.primaryColor};
`;

export const StyledSubTitle = styled.h4`
  display: inline-block;
  width: 100%;
  font-size: 30px;
  line-height: 1;
  margin: 0px;
  color: ${p => p.theme.primaryColor};
`;

export const StyledDescription = styled.h5`
  display: inline-block;
  width: 100%;
  font-size: 16px;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const StyledCompanyLogo = styled.img`
  display: inline-block;
  width: 100%;
`;

export const StyledNotFound = styled.h1`
  display: inline-block;
  width: 100%;
  color: ${p => p.theme.primaryBackgroundColor};
  font-size: 70px;
  line-height: 1;
  text-align: center;
`;
