import client from '../config/AxiosConfig';
import { ImageFormData } from '../config/ConstructFormData';

export const GetStage = async id => client.get(`/stages/${id}`);

export const CreateStage = async data => client.post('/stages', data);

export const UpdateStage = async (id, data) => client.put(`/stages/${id}`, data);

export const DeleteStage = async (id) => client.delete(`/stages/${id}`);

export const AddStageImage = async (stage, image) => client.put(`/stages/${stage}/images`, ImageFormData(image));

export const RemoveStageImage = async (stage, image) => client.delete(`/stages/${stage}/images/${image}`);

export const UpdateStagesOrder = async (data) => client.post(`/stages/order`, data);