import { GetInfo, GetTranslations } from '../../infra/requests/BaseRequests';
import { SaveInfo } from '../Info/info.actions';
import { initialize, addTranslationForLanguage } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { SaveUser } from '../User/user.actions';
import { setGlobalLoading } from "../AppLoading/app_loading.actions";
import { Languages } from "../../infra/services/translations/AvailableTranslations";

const DefineLanguage = (languages, default_language) => {
  const cookieLang = localStorage.getItem("LANG_COOKIE");
  if (cookieLang && languages.find((x) => x.code === cookieLang))
    return cookieLang;
  const browserLang = navigator.language || navigator.userLanguage;
  if (browserLang) {
    const code = browserLang.substring(0, 2);
    if (languages.find((x) => x.code === code)) return code;
  }
  if (default_language) return default_language;
  return "en";
};

export default function start() {
  return async dispatch => {
    dispatch(setGlobalLoading(true));

    try {
      const info = await GetInfo();
      const data_translations = await GetTranslations();

      const languages = info.data.languages.map((lang) =>
        Languages.find((x) => x.code === lang)
      );

      const defaultLanguage = DefineLanguage(
        languages,
        info.data.default_language
      );

      dispatch(SaveUser(info?.data?.user));
      dispatch(SaveInfo(info?.data));
      dispatch(
        initialize({
          languages,
          translation: [],
          options: {
            renderToStaticMarkup,
            defaultLanguage
          }
        })
      );

      languages.map(({ code }) =>
        dispatch(addTranslationForLanguage(data_translations.data[code], code))
      );

      dispatch(setGlobalLoading(false));
    } catch (e) {}

    dispatch(setGlobalLoading(false));
  };
}
