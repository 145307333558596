import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.svg';
import Logo2 from '../../assets/icons/logo2.svg';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle
} from './LoginPageStyles';
import { Icon, notification } from 'antd';
import { ResetPassword } from '../../infra/requests/AuthRequests';
import queryString from 'query-string';
import { withLocalize } from "react-localize-redux";
import Language from "../../components/language/Language";

const ValidateResetForm = values => {
  const errors = {};
  errors.password = FormValidator.validateField(
    'password',
    values.password,
    'required|min:6'
  );
  errors.passwordconfirm = FormValidator.validateField(
    'passwordconfirm',
    values.passwordconfirm,
    'required|min:6'
  );
  if (values.password !== values.passwordconfirm) {
    errors.passwordconfirm = new Error('Invalid password');
  }
  return errors;
};

class ResetPasswordPage extends Component {
  onSubmit = async values => {
    const {
      location: { search },
      history,
      translate
    } = this.props;
    const params = queryString.parse(search);
    const newParams = {
      password: values.password,
      token: params.token,
      email: params.email
    };

    try {
      const result = await ResetPassword(newParams);
      if (result && result.success) {
        notification.success({
          message: translate("PASSWORD_CHANGED"),
          description: translate("LOGIN_NEW_PASSWORD")
        });

        return history.push('/login');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { location: { search }, handleSubmit, submitting, translate, languages } = this.props;
    const params = queryString.parse(search);
console.log(params)
    return (
      <React.Fragment>
        {languages && languages.length > 0 && <Language fixed={true} />}
        <LoginContainer>
          <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
            <LoginCard>
              <LogoSection>
                <LogoImg src={params.app == 2 ? Logo2 : Logo} alt="logo" />
              </LogoSection>
              <FormTitle>{translate("DEFINE_PASSWORD")}</FormTitle>
              <Field
                component={TextInput}
                name="password"
                type="password"
                label={translate("NEW_PASSWORD")}
                placeholder={translate("INSERT_NEW_PASSWORD")}
                prefix={<Icon type="lock" />}
              />
              <Field
                component={TextInput}
                name="passwordconfirm"
                type="password"
                label={translate("CONFIRM_PASSWORD")}
                placeholder={translate("CONFIRM_PASSWORD_ABOVE")}
                prefix={<Icon type="lock" />}
              />
              <LoginButtonDiv>
                <BaseButton
                  variant="raised"
                  htmlType="submit"
                  type="primary"
                  loading={submitting}
                  text={translate("CHANGE_PASSWORD")}
                />
              </LoginButtonDiv>
            </LoginCard>
          </LoginForm>
        </LoginContainer>
      </React.Fragment>
    );
  }
}

ResetPasswordPage =  reduxForm({
  form: 'reset_password',
  validate: ValidateResetForm
})(ResetPasswordPage);

export default withLocalize(ResetPasswordPage);
