import { Route } from 'react-router-dom';
import React from 'react';
import { Spin } from 'antd';
import { connect } from 'react-redux';
import { SpinContainer } from "../../styles/BasicStyles";

const UnauthenticatedRoute = ({
  component: Component,
  props: cProps,
  appLoading,
  ...rest
}) => {
  return appLoading ? (
    <SpinContainer>
      <Spin size="large" />
    </SpinContainer>
  ) : (
    <Route {...rest} render={props => <Component {...props} {...cProps} />} />
  );
};

const mapStateToProps = state => ({
  appLoading: state.appLoading
});

export default connect(mapStateToProps)(UnauthenticatedRoute);
