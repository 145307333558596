import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import {
  Field,
  reduxForm,
  initialize,
  FieldArray
} from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  GetStage,
  CreateStage,
  UpdateStage,
  AddStageImage,
  RemoveStageImage
} from "../../infra/requests/StageRequests";
import { GetWay } from "../../infra/requests/WayRequests";
import { CreateImage, DeleteImage } from "../../infra/requests/ImageRequests";
import TextInput from "../../components/inputs/TextInput";
import LanguagesInput from "../../components/inputs/LanguagesInput";
import ImageSlideInput from "../../components/inputs/ImageSlideInput";
import MapInput from "../../components/inputs/MapInput";
import BaseButton from "../../components/buttons/BaseButton";
import SelectInput from '../../components/inputs/SelectInput';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle,
  TabsContainer,
  Tab
} from "../../styles/BasicStyles";
import FormValidator from "../../infra/services/validations/FormValidator";
import { Difficulty } from '../../infra/utils/Difficulties';
import TipsPage from './components/TipsPage';
import CitiesMap from '../../components/maps/CitiesMap';
import FilesInput from '../../components/inputs/FilesInput';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';

const validations = FormValidator.make({
  name: "required|emptyLanguage",
  description: "required|emptyLanguage",
  start: "required",
  end: "required",
  coordinates_start: "required",
  coordinates_end: "required"
});

class ManageStagePage extends Component {
  state = {
    isNew: false,
    loading: true,
    companies: [],
    unsaved: [],
    defaultTab: 'details',
    infoWay: undefined,
    infoStage: undefined,
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      history
    } = this.props;

    const way = await GetWay(params.way);

    if(params.id) {
      const result = await GetStage(params.id);
      dispatch(initialize("manage_stage_form", result.data));

      let defaultTab = 'details';
      if(history?.location?.search?.includes('tips=true')) defaultTab = 'tips';

      this.setState({ 
        loading: false,
        defaultTab,
        infoWay: way?.data,
        infoStage: result?.data
      });
    }
    else {
      this.setState({ 
        isNew: true,
        loading: false,
        infoWay: way?.data
      });
    }

    window.addEventListener("beforeunload", () => {
      const { unsaved } = this.state;
      if (unsaved.length) {
        unsaved.forEach(image => DeleteImage(image._id));
      }
    });
  };

  componentWillUnmount = () => {
    const { unsaved } = this.state;
    if (unsaved.length) {
      unsaved.forEach(image => DeleteImage(image._id));
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });

      const {
        match: { params },
        history
      } = this.props;
      const { isNew } = this.state;

      const payload = FlattenToFormData({ ...values, way: params.way });
      const { success } = isNew ? await CreateStage(payload) : await UpdateStage(params.id, payload);

      if(success) {
        this.setState({ loading: false, unsaved: [] }, () =>
          history.push(`/ways/${params.way}`)
        );
      } 
      else {
        this.setState({ loading: false });
      }
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const {
      history,
      match: { params }
    } = this.props;

    return history.push(`/ways/${params.way}`);
  };

  handleDeleteImage = async id => {
    const {
      match: { params }
    } = this.props;

    if(params.id) {
      return await RemoveStageImage(params.id, id);
    }
    else {
      this.setState({
        unsaved: this.state.unsaved.filter(img => img._id !== id)
      });

      return await DeleteImage(id);
    }
  };

  handleAddImage = async image => {
    const {
      match: { params }
    } = this.props;
    const { isNew } = this.state;

    if(isNew) {
      const { data, success } = await CreateImage(image);

      if(success) {
        const { unsaved } = this.state;
        this.setState({ unsaved: [...unsaved, data] });
        return data;
      }

      return undefined;
    } 
    else {
      const { data, success } = await AddStageImage(params.id, image);

      if(success) return data;
      return undefined;
    }
  };

  renderDetails = () => {
    const { handleSubmit, translate } = this.props;
    const { isNew, infoStage } = this.state;

    return (
      <Row gutter={24}>
        <Col xs={12} offset={6}>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <SectionTitle>{translate("BASIC_INFO")}</SectionTitle>
            <Field
              component={LanguagesInput}
              name="name"
              label={`${translate("NAME")} *`}
              sublabel={translate("NAME")}
              placeholder={translate("NAME_STAGE")}
              type="text"
            />
            <Field
              component={LanguagesInput}
              name="description"
              label={`${translate("ABOUT_STAGE")} *`}
              sublabel={translate("ABOUT_STAGE")}
              placeholder={translate("DESCRIPTION_STAGE")}
            />
            <SectionTitle subsection>{translate("DIRECTIONS")}</SectionTitle>
            <Field
              component={LanguagesInput}
              name="directions_intro"
              label={translate("SMALL_INTRO")}
              placeholder={translate("SMALL_INTRO_STAGE")}
            />
            <Row gutter={12}>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="distance"
                  type="text"
                  label={translate("DISTANCE")}
                  placeholder={translate("DISTANCE")}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="duration"
                  type="text"
                  label={translate("DURATION")}
                  placeholder={translate("DURATION")}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="max_altitude"
                  type="text"
                  label={translate("MAX_ALTITUDE")}
                  placeholder={translate("MAX_ALTITUDE")}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="min_altitude"
                  type="text"
                  label={translate("MIN_ALTITUDE")}
                  placeholder={translate("MIN_ALTITUDE")}
                />
              </Col>
            </Row>
            <Row gutter={12}>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="acc_ascent"
                  type="text"
                  label={translate("ACC_ASCENT")}
                  placeholder={translate("ACC_ASCENT")}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="acc_descent"
                  type="text"
                  label={translate("ACC_DESCENT")}
                  placeholder={translate("ACC_DESCENT")}
                />
              </Col>
            </Row>
            <Field
              component={SelectInput}
              data={Difficulty}
              name="difficulty"
              label={translate("DIFFICULTY")}
              placeholder={translate("AVG_DIFFICULTY_STAGE")}
            />
            <Field
              component={LanguagesInput}
              name="directions_text"
              label={translate("DIRECTIONS_TEXT")}
              placeholder={translate("DIRECTIONS_STAGE_TEXT")}
            />
            <SectionTitle subsection>{translate("USEFUL_INFO")}</SectionTitle>
            <Field
              component={LanguagesInput}
              name="info"
              label={translate("USEFUL_INFO")}
              placeholder={translate("USEFUL_INFO")}
              type="draft"
            />
            <SectionTitle subsection>{translate("GALLERY")}</SectionTitle>
            <FieldArray
              component={ImageSlideInput}
              name="images"
              label={translate("IMAGES")}
              handleAdd={this.handleAddImage}
              handleDelete={this.handleDeleteImage}
            />
            <SectionTitle subsection>{translate("POSITION")}</SectionTitle>
            <Field
              component={TextInput}
              name="start"
              type="text"
              label={`${translate("START_LOCATION")} *`}
              placeholder={translate("START_LOCATION_STAGE")}
            />
            <Field
              component={MapInput}
              name="coordinates_start"
              label={translate("COORDINATES_START")}
              required={true}
            />
            <hr style={{ marginTop: '30px', marginBottom: '17px' }}/>
            <Field
              component={TextInput}
              name="end"
              type="text"
              label={`${translate("END_LOCATION")} *`}
              placeholder={translate("END_LOCATION_STAGE")}
            />
            <Field
              component={MapInput}
              name="coordinates_end"
              label={translate("COORDINATES_END")}
              required={true}
            />
            <SectionTitle subsection>Map</SectionTitle>
            <Field
              component={TextInput}
              name='color'
              type="text"
              label={translate("COLOR_IN_THE_MAP")}
              placeholder={translate("COLOR_IN_THE_MAP_PLACEHOLDER")}
            />
            <div style={{ marginTop: '30px'}}/>
            <Field
              component={FilesInput}
              name='geojson_file'
              label={translate("GEOJSON_FILE")}
              // accept={'application/geo+json'}
            />
            {
              !isNew &&
              <div style={{ marginTop: '40px'}}>
                <CitiesMap way={infoStage} cities={[]} />
              </div>
            }
          </BaseForm>
        </Col>
      </Row>
    );
  };

  render() {
    const { handleSubmit, translate } = this.props;
    const { isNew, loading, defaultTab, infoWay } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? translate("ADD_STAGE") : translate("EDIT_STAGE");

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
            {infoWay?.name}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          {
            isNew ?
            this.renderDetails()
            :
            <React.Fragment>
              <TabsContainer defaultActiveKey={defaultTab}>
                <Tab
                  tab={translate("DETAILS")}
                  key='details'
                >
                  {this.renderDetails()}
                </Tab>
                <Tab
                  tab={translate("TIPS")}
                  key='tips'
                >
                  <TipsPage/>
                </Tab>
              </TabsContainer>
            </React.Fragment>
          }
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageStagePage = reduxForm({
  form: "manage_stage_form",
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManageStagePage);

export default withLocalize(connect()(ManageStagePage));