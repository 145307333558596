import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { reduxForm, Field } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { BaseForm } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { withLocalize } from 'react-localize-redux';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import SelectInput from '../../components/inputs/SelectInput';
import { Platforms } from '../../infra/utils/Platforms';

const validations = FormValidator.make({
  tag: 'required|noSpaces',
  value: 'required|languages',
  platform: 'required'
});

let AddTranslationModal = ({
  isOpen,
  onClose,
  onConfirm,
  handleSubmit,
  languages,
  reset,
  editable,
  translate,
  initialValues
}) => {
  return (
    <Modal
      title={initialValues ? translate("EDIT_TRANSLATION") : translate("ADD_TRANSLATION")}
      visible={isOpen}
      onOk={handleSubmit(onConfirm)}
      onCancel={() => {
        onClose();
        reset();
      }}
      maskClosable={false}
      width={900}
    >
      <BaseForm onSubmit={handleSubmit(onConfirm)}>
        <Field
          component={TextInput}
          name='tag'
          type='text'
          label={translate("TRANSLATION_TAG")}
          placeholder={translate("INSERT_TRANSLATION_TAG")}
          disabled={!editable}
        />
        <Field
          component={SelectInput}
          name='platform'
          type='text'
          data={Platforms}
          dataLabel='name'
          dataKey='_id'
          label={translate("TRANSLATION_PLATFORM")}
          placeholder={translate("INSERT_TRANSLATION_PLATFORM")}
        />

        {languages.map((lang) => (
          <Field
            key={`inputlang_${lang.code}`}
            component={TextAreaInput}
            name={`value.${lang.code}`}
            type='text'
            label={`${translate("TRANSLATION_IN")} ${GetTranslationDescription(lang.code)}`}
            minRows={3}
            maxRows={5}
            placeholder={`${translate("INSERT_TRANSLATION_FOR")} ${GetTranslationDescription(lang.code)}`}
          />
        ))}
      </BaseForm>
    </Modal>
  );
};

AddTranslationModal = reduxForm({
  form: 'add_translation_form',
  enableReinitialize: true,
  validate: validations,
})(AddTranslationModal);

AddTranslationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default withLocalize(AddTranslationModal);
