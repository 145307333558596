export const Apps = [
  { _id: 1, name: 'Caminhos de Santiago', bgColor: '#37474F' },
  { _id: 2, name: 'Caminhos Jacobeus', bgColor: '#6A1B9A' }
];

export const getApp = (value) => {
  const elem = Apps.find(elem => elem._id === value);
  if(elem) return elem;
  return null;
};

export const getAppName = (value) => {
  const elem = Apps.find(elem => elem._id === value);
  if(elem) return elem.name;
  return null;
};