import client from '../config/AxiosConfig';

export const GetInfo = async () => client.get('/info');

export const GetTranslations = async () => await client.get('/translations/platform/BO');

export const GetDynamicPages = async () => client.get('/dynamic_pages');

export const GetDynamicPage = async (id) => client.get(`/dynamic_pages/${id}`);

export const UpdateDynamicPage = async (id, data) => client.put(`/dynamic_pages/${id}`, data);

export const StagesDropdown = async way => client.get(`/stages/dropdown/${way}`);

export const GetCities = async (filters) => client.get(`/cities?filter=${filters}`);

export const GetCity = async (id) => client.get(`/cities/${id}`);

export const UpdateCity = async (id, data) => client.put(`/cities/${id}`, data);
