import styled from 'styled-components';

export const UserSection = styled.div`
  display: inline-block;
  text-align: left;
  width: 100%;
  margin: 5px 0;
  height: 18px;
`;

export const SectionLabel = styled.div`
  display: inline-block;
  width: 200px;
  font-size: 14px;
  font-weight: bold;
`;

export const SectionValue = styled.div`
  display: inline-block;
  font-size: 16px;
`;

export const PhotoSection = styled.div`
  display: inline-block;
  margin-bottom: 15px;
  margin-left: 40px;
  width: 100px;
  overflow: hidden;
  border-radius: 50%;
`;
