import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { notification, Modal, Icon, Popconfirm, Row, Col } from "antd";
import Dropzone from "react-dropzone";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ImageComponent from "../images/ImageComponent";
import HandleImageUrl from "../../infra/services/images/HandleImageUrl";
import {
  InputLabelDiv,
  ImageSlideComponent,
  ImageSlideButton,
  RemoveImage,
  EmptySlide,
  AddIcon
} from "./InputStyles";
import { withLocalize } from 'react-localize-redux';

const maxSizeText = 2;
const maxSize = 20000000;

class ImageSlideInput extends Component {
  state = {
    openModal: false,
    uploaded: undefined,
    uploading: false
  };

  buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  handleImageDrop = (accepted, rejected) => {
    const { translate } = this.props;

    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: translate("INVALID_FILE"),
        description: translate("INVALID_FILE_WARNING").replace('#max_size#', maxSizeText)
      });
    }
    const uploaded = this.buildImageObject(accepted[0]);
    this.setState({ openModal: true, uploaded });
  };

  renderDrop = ({ getRootProps, getInputProps }) => {
    const { meta } = this.props;
    const showError = meta.invalid && meta.submitFailed;
    return (
      <ImageSlideButton>
        <input {...getInputProps()} />
        <div {...getRootProps()}>
          <EmptySlide showError={showError}>
            <AddIcon type="plus" />
          </EmptySlide>
        </div>
      </ImageSlideButton>
    );
  };

  onCropComplete = () => {
    const { fields, handleAdd } = this.props;
    this.setState({ uploading: true });
    this.refs.cropper.getCroppedCanvas().toBlob(async blob => {
      const image = this.buildImageObject(blob);
      const result = await handleAdd(image);
      if (result) fields.push(result);
      this.setState({
        openModal: false,
        uploaded: undefined,
        uploading: false
      });
    });
  };

  onCancel = () => this.setState({ openModal: false, uploaded: undefined });

  handleDeleteImage = async (index, image) => {
    const { handleDelete, fields } = this.props;
    if (image._id) {
      const { success } = await handleDelete(image._id);
      if (success) fields.remove(index);
    } else fields.remove(index);
  };

  render() {
    const { label, fields, translate } = this.props;
    const { openModal, uploaded, uploading } = this.state;
    const images = fields.getAll() || [];

    return (
      <ImageSlideComponent>
        <InputLabelDiv>{label}</InputLabelDiv>
        <Row gutter={12}>
          {images.map((image, index) => (
            <Col xs={8} key={index} style={{ marginTop: "12px" }}>
              <ImageComponent
                color={image.color}
                url={image.preview || HandleImageUrl(image.file)}
                ratio={2 / 3}
              >
                <RemoveImage onClick={e => e.stopPropagation()}>
                  <Popconfirm
                    placement="topRight"
                    title={
                      <Fragment>
                        <div>{translate("DELETE_IMAGE")}</div>
                        <div>{translate("DELETE_IMAGE_WARNING")}</div>
                      </Fragment>
                    }
                    onConfirm={() => this.handleDeleteImage(index, image)}
                  >
                    <Icon type="delete" />
                  </Popconfirm>
                </RemoveImage>
              </ImageComponent>
            </Col>
          ))}
          <Col xs={8}>
            <Dropzone
              multiple={false}
              onDrop={this.handleImageDrop}
              accept="image/jpeg, image/png"
              autoCropArea={1}
              viewMode={1}
              maxSize={maxSize}
            >
              {this.renderDrop}
            </Dropzone>
          </Col>
        </Row>

        <Modal
          maskClosable={false}
          closable={false}
          title={translate("UPLOAD_IMAGE")}
          visible={openModal}
          onOk={this.onCropComplete}
          onCancel={this.onCancel}
          bodyStyle={{ padding: 0 }}
          width="600px"
          okButtonProps={{ disabled: uploading }}
          cancelButtonProps={{ disabled: uploading }}
        >
          <Cropper
            ref="cropper"
            style={{ height: 400, width: "100%" }}
            guides={true}
            viewMode={2}
            autoCropArea={1}
            aspectRatio={3 / 2}
            src={uploaded ? uploaded.preview : ""}
          />
        </Modal>
      </ImageSlideComponent>
    );
  }
}

ImageSlideInput.propTypes = {
  label: PropTypes.string,
  fields: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default withLocalize(ImageSlideInput);
