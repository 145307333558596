import styled from 'styled-components';
import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Icon,
  Collapse
} from 'antd';
const { TextArea } = Input;
const { Panel } = Collapse;

const defineBorderColor = props => {
  if (props.error) return props.theme.inputErrorColor;
  if (props.active) return props.theme.primaryColor;
  return props.theme.inputBorderColor;
};

export const InputDiv = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${p => (p.first ? '0px' : '10px')};
`;

export const SampleButtonSpace = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
`;

export const SampleInputSpace = styled.div`
  width: 100%;
  margin-top: ${p => (p.first ? '0px' : '10px')};
  height: 64px;
`;

export const InputLabelDiv = styled.div`
  display: inline-block;
  font-size: 16px;
  width: ${p => p.inlineCheckbox ? 'auto' : '100%'};
  ${({ error }) => error && 'color: red'}
  font-weight: ${p => p.importantLabel ? 'bold' : 'normal'};
`;

export const InputField = styled(Input)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${(p) =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const StyledSelectInput = styled(Select)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-select-selection {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `.ant-select-selection{
        border: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const TextAreaInputField = styled(TextArea)`
  width: 100%;

  &:focus-within,
  &:hover {
    border: 1px solid ${p => p.theme.inputFocusColor};
  }

  ${p =>
    p.error &&
    `border: 1px solid ${p.theme.inputErrorColor} !important;
`}
`;

export const InputNumberField = styled(InputNumber)`
  width: 100% !important;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input-number-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `.ant-input-number-input{
        border: 1px solid ${p.theme.inputErrorColor};
      }
  `}
`;

export const CheckboxField = styled(Checkbox)`
  margin-left: ${p => p.inlineCheckbox ? '15px' : '0px'};
`;

export const LanguagePill = styled.div`
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 6px 10px;
  border: 1px solid ${props => defineBorderColor(props)};
  color: ${({ active }) => (active ? 'white' : 'rgba(0, 0, 0, 0.65)')};
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor : 'transparent'};
  cursor: pointer;
  border-radius: 3px;
`;

export const Flag = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const DateInputField = styled(DatePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const RatingInputBox = styled.div`
  display: inline-block;
  width: 100%;
`;

export const RateImage = styled.img`
  margin: 5px;
  width: 30px;
  cursor: pointer;
`;

export const ImageInputSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => defineBorderColor(props)};
  cursor: pointer;
  ${({ filled }) => (filled ? 'border: none' : '')}
`;

export const ImageMessage = styled.div`
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
  font-size: 24px;
  margin-top: 30px;
`;

export const ImageIcon = styled(Icon)`
  font-size: 36px;
  color: ${({ error, theme }) =>
    error ? theme.inputErrorColor : 'rgba(0, 0, 0, 0.65)'};
`;

export const LanguagesComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin: 20px 0;
`;

export const AddLangButton = styled.div`
  display: inline-block;
  width: 300px;
  margin-top: 10px;
`;

export const DeleteLangIcon = styled.div`
  display: inline-block;
  position: relative;
`;

export const FormCollapse = styled(Collapse)`
  &&.ant-collapse-header {
    color: red;
  }
`;

export const FormPanel = styled(Panel)`
  .ant-collapse-header {
    color: ${({ error }) => (error ? 'red !important' : '')};
  }
`;

export const ImageSlideComponent = styled.div`
  display: inline-block;
  width: 100%;

  .ant-carousel {
    margin-top: 10px;
    .slick-slide {
      position: relative;
    }
  }
`;

export const ImageSlideButton = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;
`;

export const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 50px;
`;

export const EmptySlide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  border: 1px solid
    ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};

  ${AddIcon} {
    color: ${({ showError, theme }) =>
      showError ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

export const MapSearchInput = styled(Input)`
  &&& {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
  }
`;

export const FileSection = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.inputBorderColor};
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-top: 10px;
`;

export const ResponsePill = styled.div`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  background: ${({ transparent }) => (transparent ? 'transparent' : '#f5f5f5')};
  border-radius: 4px;
  border: 2px solid #f5f5f5;
  border-top-right-radius: ${({ single }) => (single ? 4 : 0)}px;
  border-bottom-right-radius: ${({ single }) => (single ? 4 : 0)}px;
  white-space: pre-line;
  position: relative;
`;

export const ExtraPill = styled.div`
  border: 2px solid #f5f5f5;
  background: white;
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  background-color: ${({ remove }) =>
    remove
      ? 'rgba(255, 0, 0, 0.2)'
      : ({ open }) => (open ? 'rgba(97, 149, 237, 0.2)' : null)};
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-bottom-right-radius: ${({ last }) => (last ? 4 : 0)}px;
  border-right: ${({ last }) => (last ? 2 : 0)}px solid #f5f5f5;
  cursor: ${({ link }) => (link ? 'pointer' : 'default')};
`;

export const Line = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
`;

export const InputSection = styled.div`
  display: inline-block;
  width: 80%;

  input {
    border: 1px solid ${({error, theme}) => error ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

export const ActionSection = styled.div`
  display: inline-block;
  width: 20%;

  button {
    margin-left: 10px;
    height: 32px;
    border: 1px solid
      ${({add, theme}) => (add ? theme.primaryColor : theme.inputErrorColor)};
    background: ${({add, theme}) =>
      add ? theme.primaryColor : theme.inputErrorColor};
    padding: 4px 11px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 4px;
    color: white;
  }
`;