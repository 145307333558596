import React, { Component } from 'react';
import BaseButton from '../../components/buttons/BaseButton';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SectionTitle,
  SpinLoading
} from '../../styles/BasicStyles';
import { GetWayPois } from '../../infra/requests/PoiRequests';
import StagesPage from '../stages/StagesPage';
import PoisMap from '../../components/maps/PoisMap';
import { withLocalize } from 'react-localize-redux';

class WaysPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    ready: false,
    way: {},
    filters: '',
    rows: []
  };

  componentDidMount = async () => {
    this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { match: { params } } = this.props;
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetWayPois(params.way, currentPage, pageSize, filters);

      this.setState({
        rows: result.data.items,
        way: result.data.way,
        loading: false,
        ready: true
      });
    } 
    catch (e) {
      this.setState({ loading: false, ready: true });
      console.error(e);
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;

    return history.push(`/`);
  };

  render() {
    const {
      rows,
      ready,
      way
    } = this.state;
    const {
      history,
      match: { params },
      translate
    } = this.props;

    if(!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{way.name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="edit"
              text={translate("EDIT_WAY")}
              onClick={() => history.push(`/ways/${params.way}/edit`)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <SectionTitle>{translate("BO_MAP")}</SectionTitle>
          <PoisMap way={way} pois={rows} />
          <StagesPage way={way}/>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(WaysPage);
