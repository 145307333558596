import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.svg';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  BaseLink
} from './LoginPageStyles';
import { Icon, notification } from 'antd';
import { RecoverAccount } from '../../infra/requests/AuthRequests';
import { withLocalize } from "react-localize-redux";
import Language from "../../components/language/Language";

const formRules = FormValidator.make({
  email: 'required|email'
});

class RecoverAccountPage extends Component {
  onSubmit = async values => {
    const { history, translate } = this.props;
    try {
      const { success } = await RecoverAccount(values);
      if (success) {
        notification.success({
          message: translate("REQUEST_SENT"),
          description: translate("PASSWORD_SENT")
        });
        history.push('/login');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting, translate, languages } = this.props;

    return (
      <React.Fragment>
        {languages && languages.length > 0 && <Language fixed={true} />}
        <LoginContainer>
          <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
            <LoginCard>
              <LogoSection>
                <LogoImg src={Logo} alt="logo" />
              </LogoSection>
              <FormTitle>{translate("RECOVER_ACCOUNT")}</FormTitle>
              <Field
                component={TextInput}
                name="email"
                type="email"
                label={translate("BO_EMAIL")}
                placeholder={translate("INSERT_EMAIL")}
                prefix={<Icon type="mail" />}
              />
              <BaseLink to="/login">{translate("BO_LOGIN")}</BaseLink>
              <LoginButtonDiv>
                <BaseButton
                  variant="raised"
                  htmlType="submit"
                  type="primary"
                  loading={submitting}
                  text={translate("SEND_EMAIL")}
                />
              </LoginButtonDiv>
            </LoginCard>
          </LoginForm>
        </LoginContainer>
      </React.Fragment>
    );
  }
}

RecoverAccountPage = reduxForm({
  form: 'recover_account',
  validate: formRules
})(RecoverAccountPage);

export default withLocalize(RecoverAccountPage);
