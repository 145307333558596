export default {
  //colors
  primaryColor: '#00A7AD',
  secondaryColor: '#00A7AD',
  thirdColor: '#a2a2a2',

  inputBorderColor: '#d9d9d9',
  inputFocusColor: '#E50043',
  inputErrorColor: '#f5222d',

  primaryBackgroundColor: '#ffffff',
  secondaryBackgroundColor: '#f3f3f3',

  //text size
  titleSize: '30px',
  sectionSize: '24px',
  textSize: '14px'
};
