import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { GetWay, UpdateWay } from '../../infra/requests/WayRequests';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import NumberInput from '../../components/inputs/NumberInput';
import LanguagesInput from '../../components/inputs/LanguagesInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { Difficulty } from '../../infra/utils/Difficulties';
import { getAppName } from '../../infra/utils/Apps';

const validations = FormValidator.make({
  name: 'required',
  description: 'required|emptyLanguage'
});

class ManageWayPage extends Component {
  state = {
    loading: true,
    companies: []
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    const result = await GetWay(params.way);

    dispatch(initialize('manage_way_form', result.data));
    this.setState({ loading: false });
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;

      const { success } = await UpdateWay(params.way, values);

      if(success) return history.push(`/ways/${params.way}`);
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const {
      history,
      match: { params }
    } = this.props;

    return history.push(`/ways/${params.way}`);
  };

  render() {
    const { handleSubmit, translate } = this.props;
    const { loading } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{translate("EDIT_WAY_INFO")}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label={`${translate("NAME")} *`}
              placeholder={translate("NAME_WAY")}
            />
            <Field
              component={LanguagesInput}
              name="description"
              label={`${translate("ABOUT_WAY")} *`}
              sublabel={translate("ABOUT_WAY")}
              placeholder={translate("DESCRIPTION_WAY")}
            />
            <Field
              component={NumberInput}
              name="distance"
              label={translate("TOTAL_DISTANCE")}
              placeholder={translate("TOTAL_DISTANCE_WAY")}
            />
            <Field
              component={SelectInput}
              data={Difficulty}
              name="difficulty"
              label={translate("DIFFICULTY")}
              placeholder={translate("AVG_DIFFICULTY_WAY")}
            />
            <div style={{ marginTop: '15px' }}>
              <Field
                component={CheckboxInput}
                name={`first_app`}
                label={translate("AVAILABLE_FIRST_APP").replace('#name#', getAppName(1))}
                inlineCheckbox={true}
              />
              <Field
                component={CheckboxInput}
                name={`second_app`}
                label={translate("AVAILABLE_SECOND_APP").replace('#name#', getAppName(2))}
                inlineCheckbox={true}
              />
            </div>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageWayPage = reduxForm({
  form: 'manage_way_form',
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManageWayPage);

const mapStateToProps = state => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps)(ManageWayPage));
