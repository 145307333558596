
import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  StateLabel
} from '../../styles/BasicStyles';
import {
  GetTemplates,
} from '../../infra/requests/EmailTemplatesRequests';
import EmailTemplatesFilters from './EmailTemplatesFilters';
import { connect } from 'react-redux';
import BaseButton from '../../components/buttons/BaseButton';
import { withLocalize } from 'react-localize-redux';
import { getApp, getAppName } from '../../infra/utils/Apps';

class EmailTemplatesPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    loading: true,
    ready: false,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = (record) => {
    const { history } = this.props;
    history.push(`/email-templates/${record._id}`);
  };

  handleChangePage = (currentPage) => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { translate } = this.props;
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetTemplates(currentPage, pageSize, filters);

      const columns = [
        {
          title: translate("NAME"),
          dataIndex: 'name',
          width: '50%'
        },
        {
          title: translate("HAS_HEADER_FOOTER"),
          dataIndex: 'includeHeaderFooter',
          render: (data) => this.renderState(data),
          width: '20%'
        },
        {
          title: translate("APP"),
          dataIndex: 'app',
          render: (data) => this.renderApp(data)
        }
      ];

      this.setState({
        rows: result.data.items || [],
        total: result.data.total || 0,
        ready: true, 
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ ready: true, loading: false });
      console.error(e);
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderState = (value) => {
    const { translate } = this.props;

    if(value) {
      return (
        <StateLabel
          bgColor='#389e0d'
          fontColor='#ffffff'
        >
          {translate("YES")}
        </StateLabel>
      );
    }
    return (
      <StateLabel
        bgColor='#cf1322'
        fontColor='#ffffff'
      >
        {translate("NO")}
      </StateLabel>
    );
  };

  renderApp = (app) => {
    const currentApp = getApp(app);

    if(currentApp) {
      return (
        <StateLabel 
          bgColor={currentApp.bgColor}
          fontColor='#ffffff'
        >
          {currentApp.name}
        </StateLabel>
      );
    }
    return null;
  };

  render() {
    const { history, translate } = this.props;
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize
    } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={4}>
            <PageTitle>{translate("EMAIL_TEMPLATES")}</PageTitle>
            {translate("EMAIL_TEMPLATES_LIST")}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={4}>
            <BaseButton
              type={'default'}
              icon={'edit'}
              text={translate("EDIT_HEADER_FOOTER_APP").replace('#name#', getAppName(2))}
              onClick={() => history.push('/email-templates/base-template-2')}
            />
            <BaseButton
              type={'primary'}
              icon={'edit'}
              text={translate("EDIT_HEADER_FOOTER_APP").replace('#name#', getAppName(1))}
              onClick={() => history.push('/email-templates/base-template-1')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <EmailTemplatesFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon='warning'
            emptyText={translate("NO_TEMPLATES")}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(connect()(EmailTemplatesPage));