import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import moment from "moment";
import {
  LangHeader,
  LangContainer,
  LangText,
  ArrowImg,
  SelectLangContainer,
  SelectLang,
  SelectLangText,
} from "./LanguageStyles";
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";
import Arrow from "../../assets/images/chevron-down.svg";

class Language extends Component {
  state = {
    showLanguages: false,
  };

  changeLanguage = (e, code) => {
    const { setActiveLanguage } = this.props;

    e.stopPropagation();
    e.preventDefault();

    setActiveLanguage(code);
    moment.locale(code);
    document.cookie = `LANG_COOKIE=${code}; path=/`;
    localStorage.setItem("LANG_COOKIE", code);
    this.setState({
      showLanguages: false,
    });
  };

  toggleShowLanguages = (value) => {
    this.setState({
      showLanguages: value,
    });
  };

  closeLanguagesMenu = () => {
    this.setState({
      showLanguages: false,
    });
  };

  render() {
    const { showLanguages } = this.state;
    const { languages, activeLanguage, fixed } = this.props;

    return (
      <LangHeader fixed={fixed}>
        {
          languages?.length > 0 && (
          <OutsideAlerter close={this.closeLanguagesMenu}>
            <LangContainer fixed={fixed} onClick={() => this.toggleShowLanguages(!showLanguages)}>
              <LangText>{activeLanguage ? activeLanguage.name : ""}</LangText>
              <ArrowImg showLanguages={showLanguages}>
                <img alt="arrow" src={Arrow} />
              </ArrowImg>
              <SelectLangContainer fixed={fixed} showLanguages={showLanguages}>
                <SelectLang>
                  {
                    languages
                    .filter((language) => language.code !== activeLanguage.code)
                    .map((language, index) => (
                      <SelectLangText
                        key={index}
                        onClick={(e) => this.changeLanguage(e, language.code)}
                      >
                        {language.name}
                      </SelectLangText>
                    ))}
                </SelectLang>
              </SelectLangContainer>
            </LangContainer>
          </OutsideAlerter>
        )}
      </LangHeader>
    );
  }
}

Language.propTypes = {};

export default withLocalize(Language);
