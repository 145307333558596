import LoginPage from '../../pages/auth/LoginPage';
import ResetPasswordPage from '../../pages/auth/ResetPasswordPage';
import RecoverAccountPage from '../../pages/auth/RecoverAccountPage';
import DynamicPages from '../../pages/dynamicPages/DynamicPages';
import ManageDynamicPages from '../../pages/dynamicPages/ManageDynamicPages';
import TranslationsPage from '../../pages/translations/TranslationsPage';
import EmailTemplatesPage from '../../pages/email_templates/EmailTemplatesPage';
import ManageEmailTemplatesPage from '../../pages/email_templates/ManageEmailTemplatesPage';
import ValidateAccountPage from '../../pages/auth/ValidateAccountPage';
import ConfigsPage from '../../pages/configs/ConfigsPage';
import ManageConfigsPage from '../../pages/configs/ManageConfigsPage';
import ShareAppPage from '../../pages/share/ShareAppPage';
import CitiesPage from '../../pages/cities/CitiesPage';
import ManageCitiesPage from '../../pages/cities/ManageCitiesPage';
import ShareAppJacobeusPage from '../../pages/share/ShareAppJacobeusPage';

export default [
  {
    path: '/dynamic-pages',
    component: DynamicPages,
    isAuthenticated: true
  },
  {
    path: '/dynamic-pages/:id',
    component: ManageDynamicPages,
    isAuthenticated: true
  },
  {
    path: '/login',
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: '/recover-account',
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: '/recover-password',
    component: ResetPasswordPage,
    isAuthenticated: false
  },
  {
    path: '/external/validate',
    component: ValidateAccountPage,
    isAuthenticated: false
  },
  {
    path: '/translations',
    component: TranslationsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates',
    component: EmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/email-templates/:id',
    component: ManageEmailTemplatesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs',
    component: ConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/configs/edit',
    component: ManageConfigsPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/share/poi/:id',
    component: ShareAppPage,
    isAuthenticated: false,
    role: 1
  },
  {
    path: '/share_jacobeus/poi/:id',
    component: ShareAppJacobeusPage,
    isAuthenticated: false,
    role: 1
  },
  {
    path: '/cities',
    component: CitiesPage,
    isAuthenticated: true,
    role: 1,
  },
  {
    path: '/cities/:id',
    component: ManageCitiesPage,
    isAuthenticated: true,
    role: 1,
  }
];