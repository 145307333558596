import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from 'antd';
import {
  Field,
  reduxForm,
  initialize
} from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  GetTip,
  CreateTip,
  UpdateTip
} from "../../../infra/requests/TipsRequests";
import { GetWay } from "../../../infra/requests/WayRequests";
import { GetStage } from "../../../infra/requests/StageRequests";
import LanguagesInput from "../../../components/inputs/LanguagesInput";
import BaseButton from "../../../components/buttons/BaseButton";
import SelectInput from '../../../components/inputs/SelectInput';
import DateInput from "../../../components/inputs/DateInput";
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from "../../../styles/BasicStyles";
import FormValidator from "../../../infra/services/validations/FormValidator";
import { Events } from '../../../infra/utils/Events';

const validations = FormValidator.make({
  name: "required|emptyLanguage",
  description: "required|emptyLanguage",
  start_date: "required",
  category: "required"
});

class ManageTipsPage extends Component {
  state = {
    isNew: false,
    loading: true,
    infoWay: undefined,
    infoStage: undefined
  };

  componentDidMount = async () => {
    const { match: { params }, dispatch } = this.props;

    const way = await GetWay(params.way);
    const stage = await GetStage(params.stage);

    if(params.id) {
      const result = await GetTip(params.id);
      dispatch(initialize("manage_tips_form", result.data));

      this.setState({ 
        loading: false,
        infoWay: way?.data,
        infoStage: stage?.data
      });
    }
    else {
      this.setState({ 
        isNew: true,
        loading: false,
        infoWay: way?.data,
        infoStage: stage?.data
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });

      const { match: { params }, history } = this.props;
      const { isNew } = this.state;

      const payload = { ...values, stage: params.stage };
      const { success } = isNew ? await CreateTip(payload) : await UpdateTip(params.id, values);

      if(success) {
        this.setState({ loading: false, unsaved: [] }, () =>
          history.push(`/ways/${params.way}/stages/${params.stage}?tips=true`)
        );
      } 
      else {
        this.setState({ loading: false });
      }
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history, match: { params } } = this.props;

    return history.push(`/ways/${params.way}/stages/${params.stage}?tips=true`);
  };

  render() {
    const { handleSubmit, translate } = this.props;
    const { isNew, loading, infoWay, infoStage } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? translate("ADD_TIP") : translate("EDIT_TIP");

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
            {`${infoWay?.name} > ${infoStage?.name?.en}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={LanguagesInput}
              name="name"
              label={`${translate("NAME")} *`}
              sublabel={translate("NAME")}
              placeholder={translate("NAME_TIP")}
              type="text"
            />
            <Field
              component={LanguagesInput}
              name="description"
              label={`${translate("DESCRIPTION")} *`}
              sublabel={translate("DESCRIPTION")}
              placeholder={translate("DESCRIPTION_TIP")}
            />
            <Field
              component={SelectInput}
              data={Events}
              name="category"
              label={`${translate("CATEGORY")} *`}
              placeholder={translate("CATEGORY_TIP")}
            />
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='start_date'
                  label={`${translate("START_DATE")} *`}
                />
              </Col>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name='end_date'
                  label={translate("END_DATE")}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageTipsPage = reduxForm({
  form: "manage_tips_form",
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManageTipsPage);

export default withLocalize(connect()(ManageTipsPage));