import React, { Component } from 'react';
import { Field, FieldArray, reduxForm, initialize } from 'redux-form';
import { Row, Col } from 'antd';
import {
  GetCity,
  UpdateCity
} from '../../infra/requests/BaseRequests';
import { withLocalize } from 'react-localize-redux';
import TextInput from '../../components/inputs/TextInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderTitle,
  HeaderContainer,
  PageTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading
} from '../../styles/BasicStyles';
import FormValidator from "../../infra/services/validations/FormValidator";
import NumberInput from '../../components/inputs/NumberInput';
import InputsList from '../../components/inputs/InputsList';

const validations = FormValidator.make({
  ipma_code: "required",
  ipma_districts: "required"
});

class ManageCitiesPage extends Component {
  state = {
    loading: true,
    info: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    if(params.id) {
      const result = await GetCity(params.id);
      dispatch(initialize('manage_cities', result.data));

      this.setState({ 
        loading: false,
        info: result?.data
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history } = this.props;

      const result = await UpdateCity(params.id, values);
      if(result?.success) return history.push('/cities');
      
      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/cities');
  };

  render() {
    const { handleSubmit, translate } = this.props;
    const { loading, info } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{info?.name}</PageTitle>
            {`${translate("CITIES")} > ${info?.name}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24} style={{ marginBottom: '15px' }}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name="name"
                  type="text"
                  label={translate("NAME")}
                  placeholder={translate("NAME")}
                  disabled
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginBottom: '5px' }}>
              <Col xs={24}>
                <Field
                  component={NumberInput}
                  name="ipma_code"
                  label={`${translate("IPMA_CODE")} *`}
                  placeholder={translate("IPMA_CODE")}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <FieldArray
                  component={InputsList}
                  name="ipma_districts"
                  label={`${translate("IPMA_DISTRICTS")} *`}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageCitiesPage = reduxForm({
  form: 'manage_cities',
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManageCitiesPage);

export default withLocalize(ManageCitiesPage);
