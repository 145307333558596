import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Form, Card } from 'antd';

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled(Form)`
  display: flex;
  justify-content: center;
`;

export const LoginCard = styled(Card)`
  &&& {
    background-color: ${({ theme }) => theme.secondaryBackgroundColor};
    max-width: 470px;
    padding: 20px;
    display: inline-block;
    border-radius: 5px;
  }
`;

export const LogoSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;

export const LogoImg = styled.img`
  max-width: 100%;
  width: 150px;
`;

export const LoginButtonDiv = styled.div`
  float: right;
  margin-top: 30px;
`;

export const FormTitle = styled.div`
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
`;

export const FormSubtitle = styled.div`
  font-size: 22px;
  text-align: center;
`;

export const BaseLink = styled(Link)`
  margin-top: 10px;
  display: inline-block;
`;
