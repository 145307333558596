import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton
} from '../../styles/BasicStyles';
import { GetWays, ToggleWay } from '../../infra/requests/WayRequests';
import { Icon, Popconfirm } from 'antd';
import { withLocalize } from 'react-localize-redux';
import { getAppName } from '../../infra/utils/Apps';

class WaysPage extends Component {
  state = {
    pageSize: 10,
    currentPage: 1,
    loading: false,
    columns: [],
    rows: [],
    total: 0
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/ways/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { translate } = this.props;
      const { currentPage, pageSize } = this.state;
      const result = await GetWays(currentPage, pageSize);

      const columns = [
        {
          title: translate("NAME"),
          dataIndex: 'name',
          render: value => (
            <div style={{ padding: '15px 0', fontSize: '16px' }}>{value}</div>
          ),
          width: '25%'
        },
        {
          title: translate("AVAILABLE_FIRST_APP").replace('#name#', getAppName(1)),
          dataIndex: 'first_app',
          render: value => (<div>{value ? 'Yes' : 'No'}</div>),
          width: '25%'
        },
        {
          title: translate("AVAILABLE_SECOND_APP").replace('#name#', getAppName(2)),
          dataIndex: 'second_app',
          render: value => (<div>{value ? translate("YES") : translate("NO")}</div>),
          width: '25%'
        },
        // {
        //   title: 'Ações',
        //   render: (data) => (
        //     <TableButton onClick={(e) => e.stopPropagation()}>
        //       <Popconfirm
        //         placement='topRight'
        //         title={`Quer ${
        //           data.active ? 'desativar' : 'ativar'
        //         } este Caminho?`}
        //         onConfirm={() => this.toggleActive(data)}
        //       >
        //         <Icon type={data.active ? 'close-circle' : 'check-circle'} />
        //         {data.active ? ' Desativar' : ' Ativar'}
        //       </Popconfirm>
        //     </TableButton>
        //   )
        // }
      ];

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  // toggleActive = async (record) => {
  //   this.setState({ loading: true });
  //   await ToggleWay(record._id, { active: !record.active });
  //   await this.updateDataTable();
  // };

  render() {
    const { translate } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>{translate("WAYS_LIST")}</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="fund"
            emptyText={translate("NO_WAYS")}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
            onPressRow={this.handleOnPressRow}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(WaysPage);
