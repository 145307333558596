import { Redirect, Route } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import React from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import * as loadash from 'lodash';
import { SpinContainer } from "../../styles/BasicStyles";

const AuthenticatedRoute = ({
  user,
  component: Component,
  props: cProps,
  appLoading,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        appLoading ? (
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        ) : (
          <Layout {...props} {...cProps}>
            {!loadash.isEmpty(user) ? (
              <Component {...props} {...cProps} />
            ) : (
              <Redirect to={"/login"} />
            )}
          </Layout>
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  appLoading: state.appLoading
});

export default connect(mapStateToProps)(AuthenticatedRoute);
