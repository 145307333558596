import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Row, Col, Icon } from 'antd';
import SelectInput from '../../../components/inputs/SelectInput';
import TextInput from '../../../components/inputs/TextInput';
import { DeleteFileButton } from './Styles';
import { withLocalize } from "react-localize-redux";

const StagesComponent = ({ ways, stages, changeWay, translate, fields }) => {
  const [way, setWay] = useState('');

  const selectWay = (way) => {
    setWay(way);
    changeWay(way);
  };

  const addStage = (id) => {
    const stage = stages.find(x => x._id === id);
    
    const currentFields = fields.getAll();
    const exists = currentFields?.findIndex(x => x._id === id);

    if(stage && (!currentFields || exists === -1)) {
      setWay('');
      fields.unshift(stage);
    }
  };

  const GetWayName = (index) => {
    const field = fields.get(index);
    return field?.way?.name;
  };

  const GetStageName = (index) => {
    const field = fields.get(index);
    return field?.name?.en;
  };

  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col xs={12}>
          <SelectInput
            label={translate('SELECT_WAY')}
            placeholder={translate('SELECT_WAY')}
            data={ways}
            dataKey={'_id'}
            dataLabel={'name'}
            meta={{}}
            input={{value: way, onChange: selectWay}}
          />
        </Col>
        <Col xs={12}>
          <SelectInput
            label={translate('SELECT_STAGE')}
            placeholder={translate('SELECT_STAGE')}
            data={stages}
            dataKey={'_id'}
            dataLabel={'name'}
            translatable={true}
            meta={{}}
            input={{value: '', onChange: addStage}}
          />
        </Col>
      </Row>
      <div style={{ marginTop: '20px'}}>
        {
          fields.length > 0 ?
          fields.map((field, index) =>
            <Row gutter={24} key={index}>
              <Col xs={12}>
                <TextInput
                  disabled
                  meta={{}}
                  type='text'
                  input={{value: GetWayName(index), onChange: () => null}}
                />
              </Col>
              <Col xs={10}>
                <TextInput
                  disabled
                  meta={{}}
                  type='text'
                  input={{value: GetStageName(index), onChange: () => null}}
                />
              </Col>
              <Col xs={2}>
                <DeleteFileButton onClick={() => fields.remove(index)}>
                  <Icon type='delete'/>
                </DeleteFileButton>
              </Col>
            </Row>
          )
          :
          <div>{translate('NO_SELECTED_STAGES')}</div>
        }
      </div>
    </React.Fragment>
  );
};

StagesComponent.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fields: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired
};

export default withLocalize(StagesComponent);