import React from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Select, Spin } from 'antd';
import { InputDiv, InputLabelDiv, StyledSelectInput } from './InputStyles';
const Option = Select.Option;

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const SelectInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  first,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  execAfterChange
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const changeSelect = async value => {
    input.onChange(value || '');
    if (typeof execAfterChange === "function") {
      await execAfterChange(value);
    }
  };

  return (
    <InputDiv first={first}>
      <InputLabelDiv>{label}</InputLabelDiv>
      <StyledSelectInput
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundMessage}
        filterOption={filterOption}
        allowClear={allowClear}
        onSearch={fetchData}
        onChange={changeSelect}
        value={input.value ? input.value.toString() : undefined}
        showArrow={true}
        showSearch={true}
        loading={loading}
        error={showError ? 1 : 0}
      >
        {data.map(current => (
          <Option key={current[dataKey]} disabled={current.disabled}>
            {translatable
              ? current[dataLabel][activeLanguage.code]
              : current[dataLabel]}
          </Option>
        ))}
      </StyledSelectInput>
    </InputDiv>
  );
};

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool
};

SelectInput.defaultProps = {
  data: [],
  allowClear: false,
  dataKey: '_id',
  dataLabel: 'name'
};

export default withLocalize(SelectInput);
