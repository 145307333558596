import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedRoute from './types/AuthenticatedRoute';
import UnauthenticatedRoute from './types/UnauthenticatedRoute';
import NotFound from '../pages/not_found/NotFound';
import CombinedRoutes from './CombinedRoutes';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { SpinContainer } from "../styles/BasicStyles";

const ApplicationRoutes = ({ childProps, appLoading }) => {
  return (
    <Switch>
      {CombinedRoutes.map((route, key) =>
        route.isAuthenticated ? (
          <AuthenticatedRoute
            key={key}
            path={route.path}
            exact
            component={route.component}
            props={childProps}
          />
        ) : (
          <UnauthenticatedRoute
            path={route.path}
            key={key}
            exact
            component={route.component}
            props={childProps}
          />
        )
      )}
      {
        appLoading ?
        <SpinContainer>
          <Spin size="large" />
        </SpinContainer>
        :
        <Route component={NotFound} />
      }
    </Switch>
  );
};

const mapStateToProps = state => ({
  appLoading: state.appLoading
});

export default connect(mapStateToProps)(ApplicationRoutes);