import React, { Component } from 'react';
import Table from '../../components/table/Table';
import BaseButton from '../../components/buttons/BaseButton';
import {
  SectionTitle,
  SpinLoading,
  TableButton
} from '../../styles/BasicStyles';
import { GetWayStages } from '../../infra/requests/WayRequests';
import { DeleteStage, UpdateStagesOrder } from '../../infra/requests/StageRequests';
import OrderStages from './OrderStages';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import StagesFilters from './StagesFilters';
import { Popconfirm, Row, Col, Icon } from 'antd';
import { withRouter } from 'react-router-dom';

class StagesPage extends Component {
  state = {
    pageSize: 80,
    currentPage: 1,
    loading: false,
    ready: false,
    columns: [],
    rows: [],
    total: 0,
    orderModal: false,
    filters: ''
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const {
      history,
      way
    } = this.props;
    history.push(`/ways/${way._id}/stages/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { currentPage, pageSize, filters } = this.state;
      const { way, translate } = this.props;

      const result = await GetWayStages(way._id, currentPage, pageSize, filters);

      const columns =  [
        {
          title: translate("NAME"),
          dataIndex: 'name.en',
          width: '25%'
        },
        {
          title: translate("START"),
          key: 'start',
          render: (data) => this.renderStartLocation(data),
          width: '25%'
        },
        {
          title: translate("END"),
          key: 'end',
          render: (data) => this.renderEndLocation(data),
          width: '25%'
        },
        {
          title: translate("ACTIONS"),
          render: data => (
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={translate("DELETE_STAGE_WARNING")}
                onConfirm={() => this.deleteItem(data)}
              >
                <Icon type="delete" />
                {` ${translate("DELETE")}`}
              </Popconfirm>
            </TableButton>
          )
        }
      ];

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        ready: true,
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ loading: false, ready: true });
      console.error(e);
    }
  };

  deleteItem = async item => {
    await DeleteStage(item._id);
    await this.updateDataTable();
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  renderStartLocation = (data) => {
    const { translate } = this.props;

    return (
      <React.Fragment>
        <div>{data.start}</div>
        <div>{translate("LATITUDE")}: {data.coordinates_start.latitude}</div>
        <div>{translate("LONGITUDE")}: {data.coordinates_start.longitude}</div>
      </React.Fragment>
    );
  };

  renderEndLocation = (data) => {
    const { translate } = this.props;

    return (
      <React.Fragment>
        <div>{data.end}</div>
        <div>{translate("LATITUDE")}: {data.coordinates_end.latitude}</div>
        <div>{translate("LONGITUDE")}: {data.coordinates_end.longitude}</div>
      </React.Fragment>
    );
  };

  closeOrderModal = () => {
    this.setState({ orderModal: false });
  };

  openOrderModal = (stages) => {
    const { dispatch } = this.props;
    dispatch(
      initialize(
        'manage_order_stages_form',
        { stages: [...stages] },
        false
      )
    );

    this.setState({ orderModal: true });
  };

  onSubmitOrder = async (values) => {
    try {
      this.setState({ loading: true });
      const { way } = this.props;

      if(values.stages.length > 0) {
        let orderedList = [];
        Object.keys(values.stages).forEach(function (key) {
          let obj = { _id: values.stages[key]._id, order: key };
          orderedList.push(obj);
        });

        if(orderedList.length > 0) {
          const res = await UpdateStagesOrder({ order: orderedList, way: way._id });

          if(res.success) {
            this.updateDataTable();
          }
        }
      }

      this.setState({ loading: false });
      this.closeOrderModal();
    } catch (e) {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      columns,
      rows,
      total,
      loading,
      currentPage,
      pageSize,
      ready,
      orderModal
    } = this.state;
    const {
      history,
      way,
      translate
    } = this.props;

    if(!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <Row gutter={24} style={{ marginTop: '50px', marginBottom: '10px' }}>
          <Col xs={12}>
            <SectionTitle>{translate("BO_STAGES")}</SectionTitle>
          </Col>
          <Col xs={12}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={translate("ADD_STAGE")}
              onClick={() => history.push(`/ways/${way._id}/stages/add`)}
              style={{ float: 'right' }}
            />
            <BaseButton
              type='default'
              icon='retweet'
              text={translate("EDIT_ORDER")}
              onClick={() => this.openOrderModal(rows)}
              style={{ float: 'right', marginRight: '10px' }}
            />
          </Col>
        </Row>
        <StagesFilters queryChange={this.handleFilterChange} />
        <Table
          columns={columns}
          currentPage={currentPage}
          pageSize={pageSize}
          loading={loading}
          rows={rows}
          showHeader={true}
          emptyIcon="shop"
          emptyText={translate("NO_STAGES")}
          total={total}
          rowKey={'_id'}
          hasPagination={true}
          onPressRow={this.handleOnPressRow}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <OrderStages
          open={orderModal}
          loading={loading}
          onSubmit={this.onSubmitOrder}
          closeModal={this.closeOrderModal}
        />
      </React.Fragment>
    );
  }
}

export default withLocalize(withRouter(connect()(StagesPage)));
