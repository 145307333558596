const MEAN_RADIUS_EARTH_IN_KM = 6371;
const DEG_TO_RAD_DIVISOR = 57.2957795;
const ZOOM_FACTOR = 1.6446;

const toRadians = degrees => degrees / DEG_TO_RAD_DIVISOR;

const haversine = (maxLat, minLat, maxLng, minLng) => {
  const dLat = maxLat - minLat;
  const dLng = maxLng - minLng;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(minLat) *
      Math.cos(maxLat) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return MEAN_RADIUS_EARTH_IN_KM * c;
};

const optimalZoomLevel = (maxX, minX, maxY, minY, minMapDimension = 500) => {
  const maxLat = toRadians(maxX);
  const minLat = toRadians(minX);
  const maxLng = toRadians(maxY);
  const minLng = toRadians(minY);
  const greatCircleDistance = haversine(maxLat, minLat, maxLng, minLng);
  return Math.floor(
    8 -
      Math.log(
        (ZOOM_FACTOR * greatCircleDistance) /
          Math.sqrt(2 * (minMapDimension * minMapDimension))
      ) /
        Math.log(2)
  );
};

const calculateBounds = (bounds, point) => ({
  minX: Math.min(bounds.minX, point[1]),
  maxX: Math.max(bounds.maxX, point[1]),
  minY: Math.min(bounds.minY, point[0]),
  maxY: Math.max(bounds.maxY, point[0])
});

const firstCoordinates = path => {
  if (path.features[0].geometry.type === 'MultiLineString') {
    return ({
      minX: path.features[0].geometry.coordinates[0][0][1],
      maxX: path.features[0].geometry.coordinates[0][0][1],
      minY: path.features[0].geometry.coordinates[0][0][0],
      maxY: path.features[0].geometry.coordinates[0][0][0]
    })
  }
  else {
    return ({
      minX: path.features[0].geometry.coordinates[0][1],
      maxX: path.features[0].geometry.coordinates[0][1],
      minY: path.features[0].geometry.coordinates[0][0],
      maxY: path.features[0].geometry.coordinates[0][0]
    })
  }
};

export const CalculateRegion = path => {
  const region = path.features.reduce((result, feature) => {
    const bounds = feature.geometry.coordinates[0].reduce(
      calculateBounds,
      firstCoordinates(path)
    );
    return {
      minX: Math.min(result.minX, bounds.minX),
      maxX: Math.max(result.maxX, bounds.maxX),
      minY: Math.min(result.minY, bounds.minY),
      maxY: Math.max(result.maxY, bounds.maxY)
    };
  }, firstCoordinates(path));

  return {
    latitude: (region.minX + region.maxX) / 2,
    longitude: (region.minY + region.maxY) / 2,
    latitudeDelta: region.maxX - region.minX + 0.3,
    longitudeDelta: region.maxY - region.minY + 0.3
  };
};

export const CalculateGeojsonZoom = path => {

  const region = path.features.reduce((result, feature) => {

    let bounds = undefined
    if (feature.geometry.type === 'MultiLineString') {
      bounds = feature.geometry.coordinates[0].reduce(
        calculateBounds,
        firstCoordinates(path)
      );
    } else {
      bounds = feature.geometry.coordinates.reduce(
        calculateBounds,
        firstCoordinates(path)
      );
    }
   
    return {
      minX: Math.min(result.minX, bounds.minX),
      maxX: Math.max(result.maxX, bounds.maxX),
      minY: Math.min(result.minY, bounds.minY),
      maxY: Math.max(result.maxY, bounds.maxY)
    };
  }, firstCoordinates(path));

  return {
    latitude: (region.minX + region.maxX) / 2,
    longitude: (region.minY + region.maxY) / 2,
    zoom:
      optimalZoomLevel(region.maxX, region.minX, region.maxY, region.minY) - 0.5
  };
};

export const CalculateStampsZoom = stamps => {
  if (!stamps || stamps.length === 0)
    return {
      latitude: 47,
      longitude: 10,
      zoom: 4
    };
  const points = [];
  stamps.forEach(stamp => {
    points.push([stamp.longitude, stamp.latitude]);
    points.push([
      stamp.poi.coordinates.longitude,
      stamp.poi.coordinates.latitude
    ]);
  });
  const region = points.reduce(calculateBounds, {
    minX: points[0][1],
    maxX: points[0][1],
    minY: points[0][0],
    maxY: points[0][0]
  });
  return {
    latitude: (region.minX + region.maxX) / 2,
    longitude: (region.minY + region.maxY) / 2,
    zoom:
      optimalZoomLevel(region.maxX, region.minX, region.maxY, region.minY) - 1
  };
};
