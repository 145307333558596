import React, { Component } from 'react';
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Layout, Icon } from 'antd';
import MenuItems from './MenuItems';
import { DeleteUser } from '../../redux/User/user.actions';
import { withRouter } from 'react-router-dom';
import {
  StyledMenu,
  StyledMenuItem,
  StyledNavLink,
  StyledSider,
  StyledContent,
  TransitionLayout,
  MenuSVG
} from './LayoutStyles';
import HeaderComponent from './HeaderComponent';
import { withLocalize } from "react-localize-redux";

class ResponsiveSider extends Component {
  state = {
    collapsed: true
  };

  componentDidMount = () => {
    this.mounted = true;
  };

  handleSider = () => this.setState({ collapsed: !this.state.collapsed });
  handleSiderClose = () => this.setState({ collapsed: true });

  logOut = () => {
    const { DeleteUser, history } = this.props;
    localStorage.clear();
    DeleteUser();
    history.push('/login');
  };

  renderMenuList() {
    const { user, translate } = this.props;

    return (
      <StyledMenu>
        {MenuItems.map((item, index) => {
          if (!user || user.role > item.permission_type) return null;
          return (
            <StyledMenuItem key={index}>
              <StyledNavLink
                to={item.to}
                exact={item.exact}
                activeClassName="activated"
              >
                {item.external_icon ? (
                  <MenuSVG className="anticon" size={item.size}>
                    <ReactSVG src={`${process.env.REACT_APP_BO_URL}${item.icon}`} />
                  </MenuSVG>
                ) : (
                  <Icon type={item.icon} />
                )}
                <span>{translate(item.name)}</span>
              </StyledNavLink>
            </StyledMenuItem>
          );
        })}
        <StyledMenuItem key={10} onClick={this.logOut}>
          <Icon type={'logout'} />
          <span>{translate("LOGOUT")}</span>
        </StyledMenuItem>
      </StyledMenu>
    );
  }

  render() {
    const { collapsed } = this.state;

    return (
      <Layout>
        <HeaderComponent logOut={this.logOut}/>
        <div style={{ width: '100%' }}>
          <StyledSider
            collapsible
            collapsed={collapsed}
            onCollapse={this.handleSider}
          >
            {this.renderMenuList()}
          </StyledSider>
          <TransitionLayout collapsed={collapsed}>
            <StyledContent>{this.props.children}</StyledContent>
          </TransitionLayout>
        </div>
      </Layout>
    );
  }
}

ResponsiveSider.propTypes = {};

const mapStateToProps = state => ({ user: state.user });

const mapActionToProps = dispatch =>
  bindActionCreators({ DeleteUser }, dispatch);

ResponsiveSider = connect(
  mapStateToProps,
  mapActionToProps
)(ResponsiveSider);

export default withLocalize(withRouter(ResponsiveSider));
