import React, { Component } from 'react';
import { Row, Col, notification } from 'antd';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetTemplate,
  UpdateTemplate
} from '../../infra/requests/EmailTemplatesRequests';
import {
  FormContainer,
  SpinLoading,
  Separator,
  BaseForm,
  HeaderContainer,
  PageTitle,
  HeaderTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import {
  InputContainer,
  HtmlPreview,
  ExpandWindowContainer,
  NoteContainer,
  AppAlert
} from './Styles';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/inputs/TextInput';
import { connect } from 'react-redux';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import BaseButton from '../../components/buttons/BaseButton';
import FormValidator from '../../infra/services/validations/FormValidator';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import { getAppName } from '../../infra/utils/Apps';

const baseTemplateSantiagoID = '61890ec626a78f36c86f7f6b';
const baseTemplateJacobeusID = '619d10070626c33c3a8275fe';

const FormValidations = values => {
  let errors = {};

	if(values._id === baseTemplateSantiagoID || values._id === baseTemplateJacobeusID) {
		errors = FormValidator.make({
			html: 'required'
		})(values);
	}
  else {
    errors = FormValidator.make({
      subject: 'required',
			html: 'required'
		})(values);
  }

  return errors;
};

class ManageEmailTemplatesPage extends Component {
  state = { 
    loading: true,
    info: undefined,
    viewSize: 1
  };

  componentDidMount = async () => {
    const {
      match: { params },
      history,
      dispatch
    } = this.props;

    //The "Base Template" we edit in a different area
    if(params.id === baseTemplateSantiagoID || params.id === baseTemplateJacobeusID) {
      return history.push('/email-templates');
    }

    let templateID = params.id;
    if(params.id === 'base-template-1') templateID = baseTemplateSantiagoID;
    else if(params.id === 'base-template-2') templateID = baseTemplateJacobeusID;

    const result = await GetTemplate(templateID);

    dispatch(
      initialize('manage_email_templates_form', {
        ...result.data,
      })
    );

    this.setState({ 
      loading: false,
      info: result.data
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history, translate } = this.props;
      const { info } = this.state;

      if(!info?.html) {
        notification.error({
          message: translate("ERROR"),
          description: translate("MISSING_EMAIL_CONTENT")
        });
        return this.setState({ loading: false });
      }
      
      let templateID = params.id;
      const payload = FlattenToFormData({ ...values, html: info?.html });
      if(params.id === 'base-template-1' || params.id === 'base-template-2') {
        if(!info?.html.includes('{{TEMPLATE}}')) {
          notification.error({
            message: translate("ERROR"),
            description: translate("MISSING_EMAIL_TAG")
          });
          return this.setState({ loading: false });
        }

        if(params.id === 'base-template-1') templateID = baseTemplateSantiagoID;
        else templateID = baseTemplateJacobeusID;
      }

      const { success } = await UpdateTemplate(templateID, payload);

      if (success) return history.push('/email-templates');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/email-templates');
  };

  onChange = (value) => {
    const { info } = this.state;
    info.html = value;

    this.setState({
      info
    });
  }

  changeView = () => {
    const { viewSize } = this.state;
    const size = viewSize === 1 ? 2 : 1;

    this.setState({
      viewSize: size
    });
  }

  render() {
    const { match: { params }, handleSubmit, translate } = this.props;
    const { loading, info, viewSize } = this.state;

    if(loading) return <SpinLoading />;

    let extraTitle = '';
    if(params.id === 'base-template-1') extraTitle = translate("EDIT_HEADER_FOOTER_APP").replace('#name#', getAppName(1));
    else if(params.id === 'base-template-2') extraTitle = translate("EDIT_HEADER_FOOTER_APP").replace('#name#', getAppName(2));
    else extraTitle = info?.name;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{translate("EMAIL_TEMPLATES")}</PageTitle>
            {`${translate("EMAIL_TEMPLATES")} > ${extraTitle} > ${translate("EDIT")}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            {
              (params.id === 'base-template-1' || params.id === 'base-template-2') &&
              <Row gutter={24} style={{ marginBottom: '30px' }}>
                <Col xs={24} offset={0}>
                  <AppAlert>
                    {translate('EDIT_HEADER_FOOTER_ALERT')} <span>{getAppName(params.id === 'base-template-1' ? 1 : 2)}</span>!
                  </AppAlert>
                </Col>
              </Row>
            }
            <Row gutter={24} style={{ marginBottom: '15px' }}>
              <Col xs={24} md={12} offset={0}>
                <TextInput
                  type='text'
                  label={translate("NAME")}
                  disabled={true}
                  meta={{}}
                  input={{
                    value: info?.name,
                    onChange: () => null
                  }}
                />
              </Col>
              {
                params.id !== 'base-template-1' && params.id !== 'base-template-2' &&
                <Col xs={24} md={12} offset={0}>
                  <TextInput
                    type='text'
                    label={translate("APP")}
                    placeholder={translate("APP")}
                    disabled
                    meta={{}}
                    input={{ value: getAppName(info?.app), onChange: null }}
                  />
                </Col>
              }
            </Row>
            <Separator/>
            <Row gutter={24}>
              <Col xs={24} offset={0}>
                <NoteContainer>
                  <span>{translate("NOTE")}: </span>{translate("EMAIL_TEMPLATES_NOTE")}
                </NoteContainer>
              </Col>
            </Row>
            {
              params.id !== 'base-template-1' && params.id !== 'base-template-2' &&
              <React.Fragment>
                <Row gutter={24}>
                  <Col xs={24} md={12} offset={0}>
                    <Field
                      component={CheckboxInput}
                      name={'includeHeaderFooter'}
                      label={translate("INCLUDE_HEADER_FOOTER")}
                      importantLabel={true}
                      inlineCheckbox={true}
                    />
                  </Col>
                </Row>
                <Row gutter={24} style={{ marginBottom: '40px' }}>
                  <Col xs={24} md={12}>
                    <Field
                      component={TextInput}
                      name={'subject'}
                      type='text'
                      label={`${translate("SUBJECT")} *`}
                      placeholder={translate("SUBJECT")}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            }
            <ExpandWindowContainer>
              <Row gutter={24} style={{ marginBottom: '20px' }}>
                <Col xs={24} offset={0}>
                  <BaseButton
                    type='primary'
                    icon='fullscreen'
                    text={viewSize === 1 ? translate("EXPAND_WINDOW") : translate("SHRINK_WINDOW")}
                    onClick={() => this.changeView()}
                  />
                </Col>
              </Row>
            </ExpandWindowContainer>
            <Row gutter={24}>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <InputContainer hasMargin={ viewSize === 1 ? false : true }>
                  <AceEditor
                    placeholder={translate("INSERT_EMAIL_CONTENT")}
                    mode='html'
                    theme='monokai'
                    name='html'
                    onLoad={editorInstance => {
                      editorInstance.container.style.resize = 'both';
                      document.addEventListener('mouseup', e => (
                        editorInstance.resize()
                      ));
                    }}
                    onChange={this.onChange}
                    fontSize={14}
                    showPrintMargin={false}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={info?.html}
                    setOptions={{
                      enableBasicAutocompletion: false,
                      enableLiveAutocompletion: false,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                  />
                </InputContainer>
              </Col>
              <Col md={24} lg={viewSize === 1 ? 12 : 24} offset={0}>
                <HtmlPreview
                  dangerouslySetInnerHTML={{
                  __html: info?.html
                  }}
                />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageEmailTemplatesPage = reduxForm({
  form: 'manage_email_templates_form',
  validate: FormValidations
})(ManageEmailTemplatesPage);


export default withLocalize(connect()(ManageEmailTemplatesPage));