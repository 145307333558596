import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '../../../components/table/Table';
import { Popconfirm, Icon, Row, Col } from 'antd';
import {
  SpinLoading,
  TableButton
} from '../../../styles/BasicStyles';
import BaseButton from '../../../components/buttons/BaseButton';
import TipsFilters from './TipsFilters';
import { GetTips, DeleteTip } from '../../../infra/requests/TipsRequests';
import { withRouter } from 'react-router-dom';
import { getEvent } from '../../../infra/utils/Events';
import moment from 'moment';
import { withLocalize } from 'react-localize-redux';

class TipsPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    filters: '',
    columns: [],
    rows: [],
    total: 0,
    loading: false,
    ready: false
  }

  componentDidMount = async () => {
    await this.getInfo();
  };

  getInfo = async () => {
    try {
      const { currentPage, pageSize, filters } = this.state;
      const { match: { params }, translate } = this.props;

      this.setState({ loading: true });

      const result = await GetTips(params.id, currentPage, pageSize, filters);

      const columns = [
        {
          title: translate("NAME"),
          dataIndex: 'name.en',
          width: '25%'
        },
        {
          title: translate("DATE"),
          key: 'start',
          render: (data) => this.renderDate(data),
          width: '25%'
        },
        {
          title: translate("CATEGORY"),
          key: 'category',
          render: (data) => getEvent(data.category),
          width: '25%'
        },
        {
          title: 'Actions',
          render: data => (
            <TableButton onClick={e => e.stopPropagation()}>
              <Popconfirm
                placement="topRight"
                title={translate("DELETE_TIPS_WARNING")}
                onConfirm={() => this.deleteItem(data)}
              >
                <Icon type="delete" />
                {` ${translate("DELETE")}`}
              </Popconfirm>
            </TableButton>
          )
        }
      ];

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        ready: true,
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ loading: false, ready: true });
      console.error(e);
    }
  }

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.getInfo);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.getInfo);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, this.getInfo);
  };

  handleOnPressRow = record => {
    const { history, match: { params } } = this.props;

    history.push(`/ways/${params.way}/stages/${params.id}/tips/${record._id}`);
  };

  deleteItem = async (data) => {
    try {
      await DeleteTip(data._id);
      await this.getInfo();
    } catch (e) {
      console.error(e);
    }
  };

  renderDate = (data) => {
    let date = `${moment.utc(data.start_date).format('DD/MM/YYYY')}`;
    if(data.end_date) date = `${date} - ${moment.utc(data.end_date).format('DD/MM/YYYY')}`;
    return date;
  };

  render() {
    const { 
      ready, 
      loading, 
      pageSize, 
      currentPage, 
      rows, 
      columns, 
      total
    } = this.state;
    const { history, match: { params }, translate } = this.props;

    if(!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <Row gutter={24}>
          <Col xs={24}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              text={translate("CREATE")}
              onClick={() => history.push(`/ways/${params.way}/stages/${params.id}/tips/add`)}
              style={{ float: 'right' }}
            />
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <TipsFilters queryChange={this.handleFilterChange}/>
            <Table
              columns={columns}
              currentPage={currentPage}
              pageSize={pageSize}
              loading={loading}
              rows={rows}
              showHeader={true}
              emptyIcon='warning'
              emptyText={translate("NO_TIPS")}
              total={total}
              rowKey={'_id'}
              hasPagination={true}
              onPressRow={this.handleOnPressRow}
              handleChangePage={this.handleChangePage}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default withLocalize(withRouter(connect()(TipsPage)));