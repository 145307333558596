import React from "react";
import { connect } from "react-redux";
import { 
  StyledHeader, 
  StyledLogoContainer,
  StyleHeaderLine,
  StyleUserContainer,
  StyleLogoImg,
  UserLogout
} from "./LayoutStyles";
import Logo from '../../assets/icons/logo_white.svg';
import { Icon } from 'antd';
import Language from '../language/Language';
import { withLocalize } from "react-localize-redux";

const HeaderComponent = ({ user, logOut, languages, translate }) => {
  return (
    <StyledHeader>
      <StyledLogoContainer>
        <StyleLogoImg src={Logo}/>
      </StyledLogoContainer>
      <StyleUserContainer>
        <StyleHeaderLine>{translate("WELCOME")}, { user.name }</StyleHeaderLine>
        <UserLogout title={translate("LOGOUT")} onClick={logOut}>
          <Icon type={'logout'} />
        </UserLogout>
        {
          languages?.length > 0 && (
            <Language/>
          )
        }
      </StyleUserContainer>
    </StyledHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default withLocalize(connect(mapStateToProps, null)(HeaderComponent));