import styled from "styled-components";

export const LangHeader = styled.div`
  height: 64px;
  display: flex;
  
  ${({ fixed }) => fixed ? `
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    padding-right: 20px;
  ` : `
    padding-left: 20px;
  `}
`;

export const LangContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 5px 8px 10px;
  background-color: ${({ theme, fixed }) => fixed ? theme.secondaryBackgroundColor : theme.primaryBackgroundColor};
  border-radius: 2px;
  height: 32px;
  width: 125px;
  cursor: pointer;
  position: relative;
  float: right;
  border-radius: 4px;
  box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.15);
`;

export const LangText = styled.div`
  text-transform: capitalize;
`;

export const ArrowImg = styled.div`
  transition: transform 5 linear;
  transform: ${(p) => (p.showLanguages ? "rotateZ(180deg)" : "rotateZ(0deg)")};
  margin-left: 10px;

  & svg {
    width: 10px;
    height: 6px;
  }
`;

export const SelectLangContainer = styled.div`
  background-color: ${({ theme, fixed }) => fixed ? theme.secondaryBackgroundColor : theme.primaryBackgroundColor};
  position: absolute;
  height: ${(p) => (p.showLanguages ? "auto" : "0px")};
  top: 27px;
  left: 0px;
  border-radius: 4px;
  box-shadow: 3px 5px 8px 0px rgba(0, 0, 0, 0.15);
  padding: ${(p) => (p.showLanguages ? "10px" : "0px")};
  padding-top: ${(p) => (p.showLanguages ? "5px" : "0px")};
`;

export const SelectLang = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const SelectLangText = styled.div`
  text-transform: capitalize;
  cursor: pointer;
  line-height: 40px;
  transition: color 5 linear;
  width: 100%;

  &:hover {
    color: ${(p) => p.theme.inputFocusColor};
  }
`;
