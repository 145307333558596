export default [
  {
    to: '/',
    icon: 'read',
    name: 'WAYS',
    exact: true
  },
  {
    to: '/poi',
    icon: 'environment',
    name: 'POI',
    exact: true
  },
  {
    to: '/admins',
    icon: 'lock',
    name: 'ADMINS',
    exact: true
  },
  {
    to: '/users',
    icon: 'team',
    name: 'USERS',
    exact: true
  },
  {
    to: '/cities',
    icon: 'shop',
    name: 'CITIES',
    exact: true
  },
  {
    to: '/dynamic-pages',
    icon: 'copy',
    name: 'DYNAMIC_PAGES',
    exact: true
  },
  {
    name: 'TRANSLATIONS',
    to: '/translations',
    icon: 'global',
    exact: true
  },
  {
    name: 'EMAIL_TEMPLATES',
    to: '/email-templates',
    icon: 'mail',
    exact: true
  },
  {
    name: 'SETTINGS',
    to: '/configs',
    icon: 'setting',
    exact: true,
  }
];
