import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { TableFilterSection } from '../../styles/BasicStyles';
import TextInput from '../../components/inputs/TextInput';
import SelectInput from '../../components/inputs/SelectInput';
import { withLocalize } from 'react-localize-redux';

class PoiFilters extends Component {
  state = {
    search: '',
    category: '',
    way: '',
    stage: ''
  };

  onInputChange = (field, value, timeout = 0) => {
    this.setState({ [field]: value }, () => {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const { queryChange } = this.props;
    const query = {};
    Object.keys(this.state).map(key => {
      if (this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key];
      }
      return key;
    });
    queryChange(JSON.stringify(query));
  };

  changeWay = (value) => {
    const { setWay } = this.props;

    this.setState({
      stage: ''
    });

    setWay(value);
  }

  render() {
    const { categories, ways, stages, translate } = this.props;
    const { search, category, way, stage } = this.state;

    return (
      <TableFilterSection>
        <Row gutter={24}>
          <Col xs={24} md={6}>
            <TextInput
              input={{
                value: search,
                onChange: event =>
                  this.onInputChange('search', event.target.value, 1000)
              }}
              meta={{ valid: true }}
              type="text"
              label={translate("SEARCH")}
              placeholder={translate("SEARCH_NAME")}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label={translate("CATEGORY")}
              allowClear
              placeholder={translate("SELECT_CATEGORY")}
              data={categories}
              input={{
                value: category,
                onChange: value => this.onInputChange('category', value)
              }}
              meta={{ valid: true }}
              translatable={true}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label={translate("WAY")}
              allowClear
              placeholder={translate("SELECT_WAY")}
              data={ways}
              input={{
                value: way,
                onChange: value => this.onInputChange('way', value)
              }}
              meta={{ valid: true }}
              execAfterChange={this.changeWay}
            />
          </Col>
          <Col xs={24} md={6}>
            <SelectInput
              label={translate("STAGE")}
              allowClear
              placeholder={translate("SELECT_STAGE")}
              data={stages}
              input={{
                value: stage,
                onChange: value => this.onInputChange('stage', value)
              }}
              meta={{ valid: true }}
              translatable={true}
            />
          </Col>
        </Row>
      </TableFilterSection>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.info.categories
});

export default withLocalize(connect(mapStateToProps)(PoiFilters));
