import React, { Component } from 'react';
import Logo from '../../assets/icons/logo.svg';
import {
  LoginContainer,
  LoginCard,
  LogoImg,
  LogoSection,
  FormSubtitle,
  FormTitle
} from './LoginPageStyles';
import { SpinLoading } from '../../styles/BasicStyles';
import queryString from 'query-string';
import { ValidateAccount } from '../../infra/requests/AuthRequests';
import { withLocalize } from "react-localize-redux";
import Language from "../../components/language/Language";

class ValidateAccountPage extends Component {
  state = {
    validated: false,
    error: false
  };

  componentDidMount = async () => {
    try {
      const { location: { search }, history } = this.props;
      const params = queryString.parse(search);

      if(!params.email || !params.token) return history.push('/login');

      const result = await ValidateAccount({ email: params.email, token: params.token });

      if(result?.success) {
        this.setState({
          validated: true
        });
      }
      else {
        this.setState({
          error: true
        });
      }
    } 
    catch (e) {
      console.error(e);
    }
  };

  render() {
    const { translate, languages } = this.props;
    const { validated, error } = this.state;

    return (
      <React.Fragment>
        {languages && languages.length > 0 && <Language fixed={true} />}
        <LoginContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <LoginCard>
              <LogoSection>
                <LogoImg src={Logo} alt="logo" />
              </LogoSection>
              {
                error ?
                <React.Fragment>
                  <FormTitle>{translate("ERROR")}</FormTitle>
                  <FormSubtitle>{translate("CONTACT_ADMIN")}</FormSubtitle>
                </React.Fragment>
                :
                validated ?
                <React.Fragment>
                  <FormTitle>{translate("REGISTRATION_VALIDATED")}</FormTitle>
                  <FormSubtitle>{translate("CAN_NOW_LOGIN")}</FormSubtitle>
                </React.Fragment>
                :
                <React.Fragment>
                  <FormTitle>{translate("VALIDATING_REGISTRATION")}</FormTitle>
                  <SpinLoading validatePage={true}/>
                </React.Fragment>
              }
            </LoginCard>
          </div>
        </LoginContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(ValidateAccountPage);
