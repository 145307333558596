import React from 'react';
import moment from 'moment';
import {
  UserSection,
  SectionLabel,
  SectionValue,
  PhotoSection
} from '../UserStyles';
import ImageComponent from '../../../components/images/ImageComponent';
import Placeholder from '../../../assets/images/placeholder.jpg';
import { withLocalize } from 'react-localize-redux';
import { getNationality } from '../../../infra/utils/Nationalities';
import { connect } from "react-redux";

const UserCredentialPage = ({ user, translate, info }) => {
  return (
    <React.Fragment>
      <UserSection>
        <PhotoSection>
          <ImageComponent
            url={user?.photo?.url || Placeholder}
            color={user?.photo?.color}
            width="100%"
            ratio={1}
          />
        </PhotoSection>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("NAME")}:</SectionLabel>
        <SectionValue>{user.name || translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("BO_EMAIL")}:</SectionLabel>
        <SectionValue>{user.email || translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("BIRTHDAY")}:</SectionLabel>
        <SectionValue>{user.birth ? moment(user.birth).format('DD/MM/YYYY') : translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("GENDER")}:</SectionLabel>
        <SectionValue>{user.gender || translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("NATIONALITY")}:</SectionLabel>
        <SectionValue>{user.nationality?._id ? getNationality(user.nationality._id, info?.nationalities || []) : translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("MEANS_TRAVELLING")}:</SectionLabel>
        <SectionValue>{user.means_travel || translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("MOTIVATION")}:</SectionLabel>
        <SectionValue>{user.motivation || translate("RATHER_NOT_SAY")}</SectionValue>
      </UserSection>
      <UserSection>
        <SectionLabel>{translate("CREATED")}:</SectionLabel>
        <SectionValue>{moment(user.createdAt).format('DD/MM/YYYY')}</SectionValue>
      </UserSection>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  info: state.info
});

export default withLocalize(connect(mapStateToProps)(UserCredentialPage));