import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { GetConfigs } from '../../infra/requests/ConfigsRequests';
import BaseButton from '../../components/buttons/BaseButton';
import {
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  PageContainer,
} from '../../styles/BasicStyles';
import { HeaderSection, SectionTitle, SectionAction } from './ConfigsStyles';
import InfoSection from './components/InfoSection';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';

class ConfigsPage extends Component {
  state = {
    configs: {},
  };

  componentDidMount = async () => {
    await this.getConfigurations();
  };

  getConfigurations = async () => {
    this.setState({ loading: true });

    try {
      const result = await GetConfigs();
      this.setState({
        configs: result.data[0],
        loading: false,
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { history, translate } = this.props;
    const { configs } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>{translate("SETTINGS")}</PageTitle>
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Row gutter={24}>
            <Col xs={24} md={12} offset={6}>
              <HeaderSection>
                <SectionTitle>{translate("GENERAL_INFO")}</SectionTitle>
                <SectionAction>
                  <BaseButton
                    type={'primary'}
                    icon={'edit'}
                    text={translate("EDIT")}
                    onClick={() => history.push(`configs/edit`)}
                  />
                </SectionAction>
              </HeaderSection>
              <InfoSection
                title={translate("EMAIL_NOTIFICATIONS")}
                text={configs?.email_notifications}
              />
              <InfoSection title={translate("BO_EMAIL")} text={configs?.email} />
              <InfoSection title={translate("CONTACT")} text={configs?.contact} />
            </Col>
          </Row>
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(connect()(ConfigsPage));