import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Field,
  reduxForm,
  initialize,
  FieldArray
} from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  GetPoi,
  CreatePoi,
  UpdatePoi,
  AddPoiImage,
  RemovePoiImage
} from "../../infra/requests/PoiRequests";
import { GetWaysList, GetWayStagesList } from "../../infra/requests/WayRequests";
import { CreateImage, DeleteImage } from "../../infra/requests/ImageRequests";
import TextInput from "../../components/inputs/TextInput";
import SelectInput from "../../components/inputs/SelectInput";
import LanguagesInput from "../../components/inputs/LanguagesInput";
import MapInput from "../../components/inputs/MapInput";
import ImageSlideInput from "../../components/inputs/ImageSlideInput";
import BaseButton from "../../components/buttons/BaseButton";
import {
  HeaderContainer,
  PageTitle,
  FormContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  SectionTitle
} from "../../styles/BasicStyles";
import FormValidator from "../../infra/services/validations/FormValidator";
import StagesComponent from './components/StagesComponent';

const validations = FormValidator.make({
  name: "required",
  category: "required",
  description: "required|emptyLanguage",
  coordinates: "required"
});

class ManagePoiPage extends Component {
  state = {
    isNew: false,
    loading: true,
    unsaved: [],
    info: undefined,
    ways: [],
    stages: []
  };

  componentDidMount = async () => {
    const { match: { params }, dispatch } = this.props;

    const ways = await GetWaysList();

    if(params.id) {
      const result = await GetPoi(params.id);
      dispatch(
        initialize("manage_poi_form", {
          ...result.data,
          category: result.data?.category?._id
        })
      );

      this.setState({ 
        loading: false, 
        info: result?.data,
        ways: ways?.data || []
      });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false,
        ways: ways?.data || []
      });
    }

    window.addEventListener("beforeunload", () => {
      const { unsaved } = this.state;
      if (unsaved.length) {
        unsaved.forEach(image => DeleteImage(image._id));
      }
    });
  };

  componentWillUnmount = () => {
    const { unsaved } = this.state;
    if (unsaved.length) {
      unsaved.forEach(image => DeleteImage(image._id));
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      
      const { match: { params }, history } = this.props;
      const { isNew } = this.state;

      const { success } = isNew ? await CreatePoi(values) : await UpdatePoi(params.id, values);
     
      if(success) {
        this.setState({ loading: false, unsaved: [] }, () =>
          history.push(`/poi`)
        );
      } 
      else {
        this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleDeleteImage = async id => {
    const { match: { params } } = this.props;
    
    if(params.id) {
      return await RemovePoiImage(params.id, id);
    } 
    else {
      this.setState({
        unsaved: this.state.unsaved.filter(img => img._id !== id)
      });
      return await DeleteImage(id);
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/poi');
  };

  handleAddImage = async image => {
    const { match: { params } } = this.props;
    const { isNew } = this.state;

    if(isNew) {
      const { data, success } = await CreateImage(image);
      
      if(success) {
        const { unsaved } = this.state;
        this.setState({ unsaved: [...unsaved, data] });
        return data;
      }
      return undefined;
    } 
    else {
      const { data, success } = await AddPoiImage(params.id, image);
      
      if(success) return data;
      return undefined;
    }
  };

  changeWay = async (value) => {
    const stages = await GetWayStagesList(value);

    this.setState({
      stages: stages?.data || []
    });
  }

  render() {
    const { handleSubmit, categories, translate } = this.props;
    const { isNew, loading, ways, stages, info } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? translate("ADD_POI") : translate("EDIT_POI");

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{title}</PageTitle>
            {info?.name}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <SectionTitle>{translate("BASIC_INFO")}</SectionTitle>
            <Field
              component={TextInput}
              name="name"
              type="text"
              label={`${translate("NAME")} *`}
              placeholder={translate("NAME_POI")}
            />
            <Field
              component={SelectInput}
              name="category"
              label={`${translate("CATEGORY")} *`}
              placeholder={translate("SELECT_CATEGORY")}
              data={categories}
              dataKey={"_id"}
              dataLabel={"name"}
              translatable={true}
            />
            <Field
              component={LanguagesInput}
              name="description"
              label={`${translate("ABOUT_POI")} *`}
              sublabel={translate("ABOUT_POI")}
              placeholder={translate("DESCRIPTION_POI")}
            />
            <Field
              component={LanguagesInput}
              name="schedule"
              label={translate("SCHEDULE")}
              placeholder={translate("SCHEDULE_POI")}
            />
            <Field
              component={TextInput}
              name="contact"
              type="text"
              label={translate("CONTACT")}
              placeholder={translate("CONTACT")}
            />
            <Field
              component={TextInput}
              name="email"
              type="text"
              label={translate("BO_EMAIL")}
              placeholder={translate("BO_EMAIL")}
            />
            <Field
              component={TextInput}
              name="address"
              type="text"
              label={translate("ADDRESS")}
              placeholder={translate("ADDRESS")}
            />
            <SectionTitle subsection>{translate("WAYS_AND_STAGES")}</SectionTitle>
            <FieldArray 
              component={StagesComponent} 
              name='stages' 
              ways={ways}
              stages={stages}
              changeWay={this.changeWay}
            />
            <SectionTitle subsection>{translate("GALLERY")}</SectionTitle>
            <FieldArray
              component={ImageSlideInput}
              name="images"
              label={translate("IMAGES")}
              handleAdd={this.handleAddImage}
              handleDelete={this.handleDeleteImage}
            />
            <SectionTitle subsection>{translate("POSITION")}</SectionTitle>
            <Field
              component={MapInput}
              name="coordinates"
              label={translate("COORDINATES")}
              required={true}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManagePoiPage = reduxForm({
  form: "manage_poi_form",
  validate: validations,
  onSubmitFail: FormValidator.errorHandler
})(ManagePoiPage);

const mapStateToProps = state => ({
  user: state.user,
  categories: state.info.categories
});

export default withLocalize(connect(mapStateToProps)(ManagePoiPage));
