import WaysPage from '../../pages/dashboard/WaysPage';
import ManageWayPage from '../../pages/ways/ManageWayPage';
import WayDashboardPage from '../../pages/ways/WayDashboardPage';
import ManageStagePage from '../../pages/stages/ManageStagePage';
import ManageTipsPage from '../../pages/stages/components/ManageTipsPage';
import PoiPage from '../../pages/pois/PoiPage';
import ManagePoiPage from '../../pages/pois/ManagePoiPage';

export default [
  {
    path: '/',
    component: WaysPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way',
    component: WayDashboardPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/edit',
    component: ManageWayPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/stages/:stage/tips/add',
    component: ManageTipsPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/stages/:stage/tips/:id',
    component: ManageTipsPage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/stages/add',
    component: ManageStagePage,
    isAuthenticated: true
  },
  {
    path: '/ways/:way/stages/:id',
    component: ManageStagePage,
    isAuthenticated: true
  },
  {
    path: '/poi',
    component: PoiPage,
    isAuthenticated: true
  },
  {
    path: '/poi/add',
    component: ManagePoiPage,
    isAuthenticated: true
  },
  {
    path: '/poi/:id',
    component: ManagePoiPage,
    isAuthenticated: true
  }
];
