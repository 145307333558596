import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.svg';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle,
  BaseLink
} from './LoginPageStyles';
import { Icon } from 'antd';
import { Login } from '../../infra/requests/AuthRequests';
import { SaveUser } from '../../redux/User/user.actions';
import { auth_token_key } from '../../infra/config/LocalStorageKeys';
import { withLocalize } from "react-localize-redux";
import Language from "../../components/language/Language";

const formRules = FormValidator.make({
  email: 'required|email',
  password: 'required|min:6'
});

class LoginPage extends Component {
  onSubmit = async values => {
    try {
      const { SaveUser, history } = this.props;
      const result = await Login(values);
      if (result.success) {
        localStorage.setItem(auth_token_key, result.data.token);
        SaveUser(result.data.user);
        history.push('/');
      }
    } catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting, translate, languages } = this.props;

    return (
      <React.Fragment>
        {languages && languages.length > 0 && <Language fixed={true} />}
        <LoginContainer>
          <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
            <LoginCard>
              <LogoSection>
                <LogoImg src={Logo} alt="logo" />
              </LogoSection>
              <FormTitle>{translate("BO_LOGIN")}</FormTitle>
              <Field
                component={TextInput}
                name="email"
                type="email"
                label={translate("BO_EMAIL")}
                placeholder={translate("INSERT_EMAIL")}
                prefix={<Icon type="mail" />}
              />
              <Field
                component={TextInput}
                name="password"
                type="password"
                label={translate("BO_PASSWORD")}
                placeholder={translate("INSERT_PASSWORD")}
                prefix={<Icon type="lock" />}
              />
              <BaseLink to="/recover-account">{translate("RECOVER_ACCOUNT")}</BaseLink>
              <LoginButtonDiv>
                <BaseButton
                  variant="raised"
                  htmlType="submit"
                  type="primary"
                  loading={submitting}
                  text={translate("BO_LOGIN")}
                />
              </LoginButtonDiv>
            </LoginCard>
          </LoginForm>
        </LoginContainer>
      </React.Fragment>
    );
  }
}

LoginPage = reduxForm({
  form: 'login',
  validate: formRules
})(LoginPage);

const mapStateToProps = (state, ownProps) => ({});

const mapActionToProps = dispatch => bindActionCreators({ SaveUser }, dispatch);

export default withLocalize(connect(
  mapStateToProps,
  mapActionToProps
)(LoginPage));
