import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import {
  GetConfig,
  UpdateConfigType,
} from '../../infra/requests/ConfigsRequests';
import TextInput from '../../components/inputs/TextInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator,
  HeaderContainer,
  HeaderTitle,
  PageTitle,
  HeaderButtonsContainer
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import { TransformToFormData } from '../../infra/services/formdata/TransformToFormData';
import { connect } from 'react-redux';

const configID = '618be39829546172b70e1df6';

const validations = FormValidator.make({
  email_notifications: 'required',
  email: 'required|email',
  contact: 'required'
});

class ManageConfigsPage extends Component {
  state = {
    loading: true,
  };

  componentDidMount = async () => {
    try {
      const { dispatch } = this.props;

      const configurations = await GetConfig(configID);
      dispatch(
        initialize('manage_config_form', {
          ...configurations.data,
        })
      );

      this.setState({
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { history } = this.props;

      const formDataValues = TransformToFormData(values);
      await UpdateConfigType(configID, formDataValues);
      return history.push('/configs');
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/configs');
  };

  render() {
    const { handleSubmit, translate } = this.props;
    const { loading } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{translate("SETTINGS")}</PageTitle>
            {`${translate("SETTINGS")} > ${translate("EDIT")}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer singleColumn>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              component={TextInput}
              name='email_notifications'
              type='text'
              label={`${translate("EMAIL_NOTIFICATIONS")} *`}
              placeholder={translate("ENTER_EMAIL_NOTIFICATIONS")}
            />
            <Separator />
            <Field
              component={TextInput}
              name='email'
              type='text'
              label={`${translate("BO_EMAIL")} *`}
              placeholder={translate("ENTER_EMAIL")}
            />
            <Field
              component={TextInput}
              name='contact'
              type='text'
              label={`${translate("CONTACT")} *`}
              placeholder={translate("ENTER_CONTACT")}
            />
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageConfigsPage = reduxForm({
  form: 'manage_config_form',
  validate: validations,
})(ManageConfigsPage);

export default withLocalize(connect()(ManageConfigsPage));