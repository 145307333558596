import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, InputField } from './InputStyles';

const TextInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  type,
  onPressEnter,
  label,
  first,
  placeholder,
  disabled
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <InputDiv first={first}>
      { label && <InputLabelDiv>{label}</InputLabelDiv> }
      <InputField
        disabled={disabled}
        prefix={prefix}
        size={size}
        suffix={suffix}
        type={type}
        value={input.value}
        onChange={input.onChange}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        error={showError ? 1 : 0}
      />
    </InputDiv>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func
};

export default TextInput;
