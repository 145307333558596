import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  StateLabel
} from '../../styles/BasicStyles';
import { GetDynamicPages } from '../../infra/requests/BaseRequests';
import { withLocalize } from 'react-localize-redux';
import { getApp } from '../../infra/utils/Apps';

class DynamicPages extends Component {
  state = {
    loading: false,
    columns: [],
    rows: []
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/dynamic-pages/${record._id}`);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { translate } = this.props;

      const result = await GetDynamicPages();

      const columns = [
        {
          title: translate("PAGE"),
          dataIndex: 'name',
          width: '60%'
        },
        {
          title: translate("APP"),
          dataIndex: 'app',
          render: (data) => this.renderApp(data)
        }
      ];

      this.setState({
        rows: result.data,
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  renderApp = (app) => {
    const currentApp = getApp(app);

    if(currentApp) {
      return (
        <StateLabel 
          bgColor={currentApp.bgColor}
          fontColor='#ffffff'
        >
          {currentApp.name}
        </StateLabel>
      );
    }
    return null;
  };

  render() {
    const { translate } = this.props;
    const { columns, rows, total, loading } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>{translate("DYNAMIC_PAGES")}</PageTitle>
            {translate("DYNAMIC_PAGES_MOBILE")}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <Table
            columns={columns}
            currentPage={1}
            pageSize={50}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon="copy"
            emptyText={translate("NO_PAGES")}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(DynamicPages);
