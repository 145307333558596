import client from '../config/AxiosConfig';

export const GetWay = async id => client.get(`/ways/${id}`);

export const GetWays = async (currentPage, limit) => client.get(`/ways/${currentPage}/${limit}`);

export const GetWaysList = async () => client.get(`/ways`);

export const UpdateWay = async (id, data) => client.put(`/ways/${id}`, data);

export const DeleteWay = async id => client.delete(`/ways/${id}`);

export const GetWayStages = async (id, currentPage, limit, filters) => client.get(`/ways/${id}/stages/${currentPage}/${limit}?filter=${filters}`);

export const GetWayStagesList = async (id) => client.get(`/ways/${id}/stages`);

export const ToggleWay = async (id, data) => await client.put(`/ways/${id}/toggle`, data);