import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col } from 'antd';
import {
  GetDynamicPage,
  UpdateDynamicPage
} from '../../infra/requests/BaseRequests';
import { withLocalize } from 'react-localize-redux';
import TextInput from '../../components/inputs/TextInput';
import DraftInput from '../../components/inputs/DraftInput';
import BaseButton from '../../components/buttons/BaseButton';
import {
  FormContainer,
  HeaderTitle,
  HeaderContainer,
  PageTitle,
  HeaderButtonsContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { getAppName } from '../../infra/utils/Apps';
const TabPane = Tabs.TabPane;

class ManageLessonsPage extends Component {
  state = {
    loading: true,
    info: undefined
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch
    } = this.props;

    if(params.id) {
      const result = await GetDynamicPage(params.id);
      dispatch(initialize('manage_dynamic_page', result.data));

      this.setState({ 
        loading: false,
        info: result?.data
      });
    }
  };

  onSubmit = async values => {
    try {
      this.setState({ loading: true });
      const {
        match: { params },
        history
      } = this.props;
      const result = await UpdateDynamicPage(params.id, values);
      if (result && result.success) return history.push('/dynamic-pages');
      else this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/dynamic-pages');
  };

  render() {
    const { handleSubmit, activeLanguage, languages, translate } = this.props;
    const { loading, info } = this.state;

    if(loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={2}>
            <PageTitle>{info?.name}</PageTitle>
            {`${translate("DYNAMIC_PAGES")} > ${info?.name}`}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={2}>
            <BaseButton
              type="primary"
              icon="save"
              text={translate("SAVE")}
              onClick={handleSubmit(this.onSubmit)}
            />
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24} style={{ marginBottom: '30px' }}>
              <Col xs={12}>
                <Field
                  component={TextInput}
                  name="name"
                  type="text"
                  label={translate("NAME")}
                  placeholder={translate("NAME")}
                  disabled
                />
              </Col>
              <Col xs={12}>
                <TextInput
                  type='text'
                  label={translate("APP")}
                  placeholder={translate("APP")}
                  disabled
                  meta={{}}
                  input={{ value: getAppName(info?.app), onChange: null }}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={DraftInput}
                  name={`content.${activeLanguage?.code}`}
                  label={translate("PAGE_CONTENT")}
                />
              </Col>
              <Col xs={12}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={DraftInput}
                          name={`content.${language.code}`}
                          label={translate("PAGE_CONTENT")}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageLessonsPage = reduxForm({
  form: 'manage_dynamic_page'
})(ManageLessonsPage);

export default withLocalize(ManageLessonsPage);
