import client from '../config/AxiosConfig';

export const GetAdmin = async id => await client.get(`/management/${id}`);

export const GetAdmins = async (currentPage, limit, filters) =>
  await client.get(`/management/${currentPage}/${limit}?filter=${filters}`);

export const UpdateAdmin = async (id, data) =>
  await client.put(`/management/${id}`, data);

export const CreateAdmin = async data => await client.post(`/management`, data);

export const RevokeAccess = async id =>
  await client.delete(`/management/${id}`);
