import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  TableButton,
  SpinLoading
} from '../../styles/BasicStyles';
import { GetPois, DeletePoi } from '../../infra/requests/PoiRequests';
import BaseButton from '../../components/buttons/BaseButton';
import { withLocalize } from 'react-localize-redux';
import PoiFilters from './PoiFilters';
import { GetWaysList, GetWayStagesList } from '../../infra/requests/WayRequests';

const REACT_APP_API = process.env.REACT_APP_API;

class PoiPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    columns: [],
    rows: [],
    total: 0,
    filters: '',
    ready: false,
    ways: [],
    stages: [],
    filterWay: ''
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { translate } = this.props;
      const { currentPage, pageSize, filters, filterWay } = this.state;

      const ways = await GetWaysList();
      let stages = [];
      if(filterWay) stages = await GetWayStagesList(filterWay);

      const result = await GetPois(currentPage, pageSize, filters);

      const columns = [
        {
          title: translate("NAME"),
          dataIndex: 'name',
          width: '20%'
        },
        {
          title: translate("CATEGORY"),
          dataIndex: 'category',
          render: value => value?.name?.en,
          width: '15%'
        },
        {
          title: translate("BO_STAGES"),
          dataIndex: 'stages',
          render: value => this.renderStages(value),
          width: '40%'
        },
        {
          title: translate("ACTIONS"),
          render: data => (
            <React.Fragment>
              <TableButton onClick={e => this.getQrCode(e, data)}>
                <Icon type="qrcode" />
                {` ${translate("GET_QRCODE")}`}
              </TableButton>
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title={translate("DELETE_POI_WARNING")}
                  onConfirm={() => this.deleteItem(data)}
                >
                  <Icon type="delete" />
                  {` ${translate("DELETE")}`}
                </Popconfirm>
              </TableButton>
            </React.Fragment>
          )
        }
      ];

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        columns,
        ready: true,
        ways: ways?.data || [],
        stages: stages?.data || []
      });
    } catch (e) {
      this.setState({ loading: false, ready: true });
      console.error(e);
    }
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/poi/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  deleteItem = async item => {
    await DeletePoi(item._id);
    await this.updateDataTable();
  };

  getQrCode = async (event, item) => {
    event.stopPropagation();
    window.open(`${REACT_APP_API}/pois/${item._id}/qrcode`);
  };

  setWay = async (value) => {
    this.setState({
      filterWay: value
    });
  };

  renderStages = (stages) => {
    if(stages?.length > 0) {
      return stages.map((stage, index) => 
        <div key={index}>{`${stage?.name?.en} (${stage?.way?.name})`}</div>
      );
    }
  }

  render() {
    const { columns, rows, total, loading, currentPage, pageSize, ready, ways, stages } = this.state;
    const { history, translate } = this.props;

    if(!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>{translate("POI")}</PageTitle>
            {translate("POI_LIST")}
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type={'primary'}
              icon={'plus'}
              block
              text={translate("CREATE")}
              onClick={() => history.push('/poi/add')}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <PoiFilters 
            ways={ways}
            stages={stages}
            setWay={this.setWay}
            queryChange={this.handleFilterChange} 
          />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="home"
            emptyText={translate("NO_POI")}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(PoiPage);
