import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { Select, Popconfirm, Icon } from 'antd';
import React from 'react';
import TextAreaInput from './TextAreaInput';
import TextInput from './TextInput';
import DraftInput from './DraftInput';
import {
  LanguagesComponent,
  InputLabelDiv,
  AddLangButton,
  DeleteLangIcon,
  FormCollapse,
  FormPanel
} from './InputStyles';

const { Option } = Select;

const getLangName = code => {
  if (code === 'en') return 'English';
  if (code === 'pt') return 'Portuguese';
  if (code === 'es') return 'Spanish';
  if (code === 'fr') return 'French';
  if (code === 'de') return 'Deutsch';
  return '';
};

const getLabel = (label, code) => {
  if (code === 'en') return `${label} in English`;
  if (code === 'pt') return `${label} in Portuguese`;
  if (code === 'es') return `${label} in Spanish`;
  if (code === 'fr') return `${label} in French`;
  if (code === 'de') return `${label} in Deutsch`;
  return '';
};

const LanguagesInput = ({
  label,
  sublabel,
  name,
  input,
  placeholder,
  activeLanguage,
  languages,
  meta,
  errors,
  error,
  type = 'textArea',
  translate
}) => {
  const langs = Object.keys(input.value ? input.value : { en: '' });
console.log(langs)
  const showError = meta.invalid && meta.submitFailed;

  const applyError = key => {
    const exist = meta.error && Object.keys(meta.error).find(x => x === key);
    return showError && exist;
  };

  const changeInput = (event, code) => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : { en: '' };
    if(type === 'draft') result[code] = event;
    else result[code] = event.target.value;
    input.onChange(result);
  };
  const addLang = key => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : { en: '' };
    result[key] = '';
    input.onChange(result);
  };
  const removeLang = key => {
    const result =
      input && input.value && input.value !== ''
        ? { ...input.value }
        : { en: '' };
    delete result[key];
    input.onChange(result);
  };
  const deleteIcon = key => {
    if (key !== 'en') {
      return (
        <DeleteLangIcon onClick={e => e.stopPropagation()}>
          <Popconfirm
            placement="top"
            title={translate("DELETE_LANGUAGE_WARNING")}
            onConfirm={() => removeLang(key)}
          >
            <Icon type="delete" />
          </Popconfirm>
        </DeleteLangIcon>
      );
    }
    return null;
  };

  return (
    <LanguagesComponent>
      <InputLabelDiv>{label}</InputLabelDiv>
      <FormCollapse accordion>
        {langs.map(key => (
          <FormPanel
            error={applyError(key)}
            header={getLabel(sublabel || label, key)}
            key={key}
            extra={deleteIcon(key)}
          >
            {
              type === 'text' ?
              <TextInput
                meta={{
                  invalid: applyError(key),
                  submitFailed: meta.submitFailed
                }}
                label=""
                placeholder={placeholder}
                input={{
                  value: input?.value?.[key],
                  onChange: e => changeInput(e, key)
                }}
                type='text'
              />
              :
              type === 'draft' ?
              <DraftInput
                meta={{
                  invalid: applyError(key),
                  submitFailed: meta.submitFailed
                }}
                label=""
                placeholder={placeholder}
                input={{
                  value: input?.value?.[key],
                  onChange: e => changeInput(e, key)
                }}
              />
              :
              <TextAreaInput
                meta={{
                  invalid: applyError(key),
                  submitFailed: meta.submitFailed
                }}
                label=""
                placeholder={placeholder}
                input={{
                  value: input?.value?.[key],
                  onChange: e => changeInput(e, key)
                }}
              />
            }
          </FormPanel>
        ))}
      </FormCollapse>
      <AddLangButton>
        <Select
          placeholder={translate("ADD_ANOTHER_LANGUAGE")}
          onSelect={code => addLang(code)}
          value={translate("ADD_ANOTHER_LANGUAGE")}
        >
          {languages.map(lang => {
            if (!langs.find(x => lang.code === x))
              return (
                <Option key={lang.code} value={lang.code}>
                  {getLangName(lang.code)}
                </Option>
              );
            return null;
          })}
        </Select>
      </AddLangButton>
    </LanguagesComponent>
  );
};

LanguagesInput.propTypes = {
  label: PropTypes.string
};

export default withLocalize(LanguagesInput);
