import React, { Component } from 'react';
import { Popconfirm, Icon } from 'antd';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  TableButton
} from '../../styles/BasicStyles';
import { GetUsers, DeleteUser } from '../../infra/requests/UsersRequests';
import { withLocalize } from 'react-localize-redux';
import UserFilters from './UserFilters';

class UsersPage extends Component {
  state = {
    pageSize: 30,
    currentPage: 1,
    loading: false,
    columns: [],
    rows: [],
    total: 0,
    filters: ''
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/users/${record._id}`);
  };

  handleChangePage = currentPage => {
    this.setState({ currentPage }, this.updateDataTable);
  };

  handleChangeRowsPerPage = (currentSize, pageSize) => {
    this.setState({ pageSize }, this.updateDataTable);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  deleteItem = async data => {
    try {
      await DeleteUser(data._id);
      await this.updateDataTable();
    } catch (e) {
      console.error(e);
    }
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { translate } = this.props;
      const { currentPage, pageSize, filters } = this.state;

      const result = await GetUsers(currentPage, pageSize, filters);
      
      const columns = [
        {
          title: translate("NAME"),
          dataIndex: 'name',
          width: '30%'
        },
        {
          title: translate("BO_EMAIL"),
          dataIndex: 'email',
          width: '30%'
        },
        {
          title: translate("ACTIONS"),
          render: data => (
            <React.Fragment>
              <TableButton onClick={e => e.stopPropagation()}>
                <Popconfirm
                  placement="topRight"
                  title={translate("DELETE_USERS_WARNING")}
                  onConfirm={() => this.deleteItem(data)}
                >
                  <Icon type="delete" />
                  {` ${translate("DELETE")}`}
                </Popconfirm>
              </TableButton>
            </React.Fragment>
          )
        }
      ];

      this.setState({
        rows: result.data.items,
        total: result.data.total,
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  render() {
    const { translate } = this.props;
    const { columns, rows, total, loading, currentPage, pageSize } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>{translate("USERS")}</PageTitle>
            {translate("USERS_LIST")}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <UserFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={currentPage}
            pageSize={pageSize}
            loading={loading}
            rows={rows}
            showHeader={true}
            emptyIcon="team"
            emptyText={translate("NO_USERS")}
            total={total}
            rowKey={'_id'}
            hasPagination={true}
            onPressRow={this.handleOnPressRow}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(UsersPage);
