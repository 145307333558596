import React, { Component } from 'react';
import Table from '../../components/table/Table';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle
} from '../../styles/BasicStyles';
import { GetCities } from '../../infra/requests/BaseRequests';
import { withLocalize } from 'react-localize-redux';
import CitiesFilters from './CitiesFilters';

class CitiesPage extends Component {
  state = {
    loading: false,
    columns: [],
    rows: [],
    filters: ''
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  handleOnPressRow = record => {
    const { history } = this.props;
    history.push(`/cities/${record._id}`);
  };

  handleFilterChange = (filters) => {
    this.setState({ filters, currentPage: 1 }, this.updateDataTable);
  };

  updateDataTable = async () => {
    this.setState({ loading: true });
    try {
      const { translate } = this.props;
      const { filters } = this.state;

      const result = await GetCities(filters);

      const columns = [
        {
          title: translate("PAGE"),
          dataIndex: 'name',
          width: '40%'
        },
        {
          title: translate("IPMA_DISTRICTS"),
          dataIndex: 'ipma_districts',
          width: '35%',
          render: (data) => this.renderDistricts(data)
        },
        {
          title: translate("IPMA_CODE"),
          dataIndex: 'ipma_code'
        }
      ];

      this.setState({
        rows: result.data,
        loading: false,
        columns
      });
    } catch (e) {
      this.setState({ loading: false });
      console.error(e);
    }
  };

  renderDistricts = (districts) => {
    if(districts?.length > 0) {
      return districts.map((district, index) => 
        <div key={index}>{district}</div>
      );
    }
  }

  render() {
    const { translate } = this.props;
    const { columns, rows, total, loading } = this.state;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={0}>
            <PageTitle>{translate("CITIES")}</PageTitle>
            {translate("CITIES_LIST")}
          </HeaderTitle>
        </HeaderContainer>
        <PageContainer>
          <CitiesFilters queryChange={this.handleFilterChange} />
          <Table
            columns={columns}
            currentPage={1}
            pageSize={1}
            loading={loading}
            onPressRow={this.handleOnPressRow}
            rows={rows}
            showHeader={true}
            emptyIcon="folder-open"
            emptyText={translate("NO_CITIES")}
            total={total}
            rowKey={'_id'}
            hasPagination={false}
          />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(CitiesPage);
