import client from '../config/AxiosConfig';

export const GetTip = async id => client.get(`/stages_tips/${id}`);

export const CreateTip = async data => client.post('/stages_tips', data);

export const UpdateTip = async (id, data) => client.put(`/stages_tips/${id}`, data);

export const GetTips = async (id, currentPage, limit, filters) => client.get(`/stages_tips/${id}/${currentPage}/${limit}?filter=${filters}`);

export const DeleteTip = async (id) => client.delete(`/stages_tips/${id}`);