import React, { Component } from 'react';
import {
  PageContainer,
  PageTitle,
  HeaderContainer,
  HeaderTitle,
  HeaderButtonsContainer,
  SpinLoading
} from '../../styles/BasicStyles';
import BaseButton from '../../components/buttons/BaseButton';
import { GetUser } from '../../infra/requests/UsersRequests';
import UserProfile from './components/UserProfile';
import { withLocalize } from 'react-localize-redux';

class UserCredentialPage extends Component {
  state = {
    ready: false,
    user: {}
  };

  componentDidMount = async () => {
    await this.updateDataTable();
  };

  updateDataTable = async () => {
    const {
      match: { params }
    } = this.props;

    try {
      const result = await GetUser(params.id);
      this.setState({
        user: result.data,
        ready: true
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleOnCancel = () => {
    const { history } = this.props;
    return history.push('/users');
  };

  render() {
    const { translate } = this.props;
    const { user, ready } = this.state;
    if(!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <HeaderContainer>
          <HeaderTitle buttons={1}>
            <PageTitle>{user.name}</PageTitle>
          </HeaderTitle>
          <HeaderButtonsContainer buttons={1}>
            <BaseButton
              type="default"
              icon="close"
              text={translate("CANCEL")}
              onClick={this.handleOnCancel}
            />
          </HeaderButtonsContainer>
        </HeaderContainer>
        <PageContainer>
          <UserProfile user={user} />
        </PageContainer>
      </React.Fragment>
    );
  }
}

export default withLocalize(UserCredentialPage);
