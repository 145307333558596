import React from "react";
import BaseButton from "../../components/buttons/BaseButton";
import Logo from "../../assets/icons/logo.svg";
import {
  StyledNotFoundContainer,
  StyledTitle,
  StyledDescription,
  StyledCompanyLogo,
  StyledNotFound,
  StyledNotFoundGroup,
  StyledSubTitle,
  StyledRightContainer,
  StyledLeftContainer
} from "./NotFoundStyles";
import { withLocalize } from 'react-localize-redux';

const NotFound = ({ history, translate }) => (
  <StyledNotFoundContainer>
    <StyledNotFoundGroup>
      <StyledLeftContainer>
        <StyledNotFound>404</StyledNotFound>
        <StyledCompanyLogo src={Logo} />
      </StyledLeftContainer>
      <StyledRightContainer>
        <StyledTitle>{translate("OOPS")}, </StyledTitle>
        <StyledSubTitle>{translate("YOUR_ARE_LOST")}</StyledSubTitle>
        <StyledDescription>
          {translate("NOT_FOUND_TEXT")}
        </StyledDescription>
        <BaseButton
          disabled={false}
          loading={false}
          text={translate("BACK_TO_HOME")}
          onClick={() => history.push("/")}
          type={"primary"}
        />
      </StyledRightContainer>
    </StyledNotFoundGroup>
  </StyledNotFoundContainer>
);

export default withLocalize(NotFound);
