import styled from "styled-components";
import { Card } from "antd";

export const ShareContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.secondaryBackgroundColor};
`;

export const ShareCard = styled(Card)`
  &&& {
    margin: 100px 0;
    background-color: white;
    width: 420px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.thirdColor};
  }
`;

export const LogoSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;

export const LogoImg = styled.img`
  max-width: 100%;
  width: 150px;
`;