import React from 'react';
import PropTypes from 'prop-types';
import { Icon, notification } from 'antd';
import Dropzone from 'react-dropzone';
import { 
  FileSection,
  InputLabelDiv, 
  ResponsePill, 
  ExtraPill 
} from './InputStyles';
import { withLocalize } from 'react-localize-redux';

const { REACT_APP_IMAGES_URL } = process.env;
const maxSizeText = 16;
const maxSize = 160000000;

const FilesInput = ({ label, input, accept, translate }) => {
  const openFile = (id) =>
    window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');

  const buildImageObject = (blob) => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob,
  });

  const handleDrop = (accepted, rejected) => {
    if(accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: translate("INVALID_FILE"),
        description: translate("INVALID_FILE_WARNING").replace('#max_size#', maxSizeText)
      });
    }

    const uploaded = buildImageObject(accepted[0]);
    input.onChange(uploaded);
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={false}
        onDrop={handleDrop}
        accept={accept}
        maxSize={maxSize}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <React.Fragment>
              <FileSection {...getRootProps()}>
                <input {...getInputProps()} />
                <Icon type='file'/>&nbsp;{translate("DRAG_FILE")}
              </FileSection>
              <div style={{ marginTop: '5px' }}>{translate("MAX_FILE_SIZE")}: <strong>16MB</strong></div>
            </React.Fragment>
          );
        }}
      </Dropzone>
    );
  };

  const removeFile = () => {
    input.onChange(null);
  }

  if(input.value) {
    const saved = input.value._id;
    const filename = saved ? input.value.filename : input.value.blob.name;
    const size = (
      (saved ? input.value.length : input.value.size) /
      (1024 * 1024)
    ).toFixed(2);

    return (
      <div>
        { label && <InputLabelDiv>{label}</InputLabelDiv> }
        <ResponsePill single={false}>{filename}</ResponsePill>
        <ExtraPill>{size}MB</ExtraPill>
        {
          saved &&
          <React.Fragment>
            <ExtraPill link onClick={() => openFile(input.value._id)}>
            {translate("OPEN_FILE")}
            </ExtraPill>
          </React.Fragment>
        }
        <ExtraPill last link remove onClick={() => removeFile()}>
        {translate("DELETE_FILE")}
        </ExtraPill>
        {renderDropZone()}
      </div>
    );
  }

  return (
    <div>
      { label && <InputLabelDiv>{label}</InputLabelDiv> }
      {renderDropZone()}
    </div>
  )
};

FilesInput.propTypes = {
  input: PropTypes.object.isRequired,
  //accept: PropTypes.string.isRequired,
  label: PropTypes.string
};

export default withLocalize(FilesInput);