import { SET_GLOBAL_LOADING } from '../ActionsType';

const globalLoading = false;

export default (state = globalLoading, action) => {
  switch (action.type) {
    case SET_GLOBAL_LOADING:
      return action.isLoading;

    default:
      return state;
  }
};
