import React, { useState } from 'react';
import { Input, Icon, Popconfirm } from 'antd';
import { 
  InputDiv, 
  InputLabelDiv,
  Line,
  InputSection,
  ActionSection
} from './InputStyles';
import { withLocalize } from 'react-localize-redux';

const InputsList = ({ fields, label, placeholder, translate, meta }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const list = fields.getAll() || [];

  const { invalid, submitFailed } = meta;
  const formError = invalid && submitFailed;

  const onAdd = () => {
    if(value && value !== '') {
      fields.push(value);
      setValue('');
      setError(false);
    } else setError(true);
  };

  const onRemove = index => fields.remove(index);

  return (
    <InputDiv>
      <InputLabelDiv>{label}</InputLabelDiv>
      { placeholder && <Line style={{marginBottom: '10px'}}>{placeholder}</Line> }
      <Line>
        <InputSection error={error || formError}>
          <Input
            type="text"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </InputSection>
        <ActionSection add>
          <button type="button" onClick={onAdd}>
            <Icon type="plus" />
          </button>
        </ActionSection>
      </Line>
      {
        list.map((email, index) =>
          <Line key={index}>
            <InputSection>
              <Input type="text" value={email} disabled={true} />
            </InputSection>
            <ActionSection>
              <Popconfirm
                placement="topRight"
                title={translate('DELETE_WARNING')}
                onConfirm={() => onRemove(index)}
              >
                <button type="button">
                  <Icon type="delete" />
                </button>
              </Popconfirm>
            </ActionSection>
          </Line>
        )
      }
    </InputDiv>
  );
};

export default withLocalize(InputsList);